import React, { useState, useEffect } from 'react';
import { FaTrash, FaTimes } from "react-icons/fa";
import Modal from '../Modal';
import { FavoritesAPI } from '../../api/favoritesAPI';


/*
    This class creates a DropdownMenu. There is a search bar that searches for different things in the dropdown.
    Deletion can be done by pressing the trash can icon. 

    INPUTS:
        initialOptions: Array of Prompts
    
    Update Array : Get Array of prompts from mongo and replace Array. 
    Delete Element : Delete array of prompts from mongo and delete associated component in Array

*/

const SelectPromptDesktop = ({ promptMenuProps }) => {
    const [options, setOptions] = useState([]);
    const {initialOptions, initialDropDown, updateFunc, updateStateFunc} = promptMenuProps;
    useEffect(() => {
        setOptions(initialOptions);
    }, [initialOptions]);

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [hoveredOption, setHoveredOption] = useState(null);
    const [selectedDataPrompt, setSelectedDataPrompt] = useState("");

    const handleExit = (e) => {

        updateFunc(false);
    }

    const handleSendPrompt = (e) => {
        updateFunc(false);
        updateStateFunc(selectedDataPrompt);
    }

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleDelete = async (optionToDelete) => {
        try {
            // Call the API to delete the prompt from MongoDB
            await FavoritesAPI.delFavorite(optionToDelete.promptID);

            // Update the options state to remove the deleted option
            setOptions(options.filter((option) => option.promptID !== optionToDelete.promptID));

            // Reset the selectedOption if the deleted option was selected
            if (selectedOption?.promptID === optionToDelete.promptID) {
                setSelectedOption(null);
            }
        } catch (error) {
            console.error('Error deleting prompt:', error);
            // Handle the error appropriately (e.g., show an error message)
        }
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setSelectedDataPrompt(option.content)

    };

    return (
        <Modal title="" modalOpen={initialDropDown} useClose={false} classes='dropdown-modal' useRelative={false}>
            <div className="bg-dark-blue w-[60rem] rounded-3xl flex flex-col justify-between pt-2 h-[1rem] min-h-[37rem] max-h-[20rem]">
                <div className="flex items-center justify-between px-10 py-4">
                    <div className="flex items-center text-xl text-white">
                        <span>Browse Prompts</span>
                    </div>
                    <button className="text-white" onClick={handleExit}><FaTimes /></button>
                </div>

                <div className="flex flex-row-reverse overflow-auto pr-10 pb-5 pt-0 h-full rounded-3xl">
                    <div className="flex flex-grow-0 basis-3/5 bg-black border-box rounded-3xl overflow-auto">
                        <div className='flex flex-col'>
                            <div className="text-white text-[1.5rem] break-words font-extrabold px-6 pb-1 pt-6 max-w-[34.5rem] overflow-hidden whitespace-nowrap text-ellipsis">
                                {selectedOption ? selectedOption.name : 'Select an option to see the prompt'}
                            </div>
                            <div className="text-white text-base break-words overflow-auto pl-6 pr-3 pt-1 pb-6 max-h-[22rem]">
                                {selectedOption ? selectedOption.content : ''}
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col flex-grow pr-7 pl-10 pt-1.5">
                        <div className="flex flex-col mb-4">
                            <input
                                id="searchOptions"
                                type="text"
                                placeholder="Seach..."
                                value={searchTerm}
                                onChange={handleSearch}
                                className="bg-black text-white rounded-lg p-2"
                            />
                        </div>

                        <div className="flex flex-col overflow-y-auto">
                            <ul id="optionsList" className="bg-black text-white rounded-lg px-6 pt-3">
                                {options
                                    .filter((option) =>
                                        option.name.toLowerCase().includes(searchTerm.toLowerCase())
                                    )
                                    .map((option) => (
                                        <li
                                            key={option.promptID}
                                            className="flex justify-between items-center mb-4"
                                            onMouseEnter={() => setHoveredOption(option)}
                                            onMouseLeave={() => setHoveredOption(null)}
                                        >
                                            <span
                                                className={`overflow-x-auto max-w-[15rem] w-[13.5rem] whitespace-nowrap cursor-pointer ${hoveredOption === option ? 'font-bold text-blue-400' : ''}`}
                                                onClick={() => handleOptionClick(option)}
                                            >
                                                {option.name}
                                            </span>
                                            <button onClick={() => handleDelete(option)}>
                                                <FaTrash />
                                            </button>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="flex flex-row-reverse justify-between pb-4 pr-10">
                    <button
                        onClick={handleSendPrompt}
                        className="bg-light-blue text-white rounded-lg px-5 py-2 capitalize"
                    >
                        Select Prompt
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default SelectPromptDesktop;
