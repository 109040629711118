import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import { MdCalendarMonth } from "react-icons/md";
import "react-datepicker/dist/react-datepicker.css";
import skechai_logo from '../assets/Dark_BG_SkechAI_logo_sm.png';
import { UsageAPI, UsageType } from '../api/usageAPI';
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
  } from 'chart.js';

import { Chart } from 'react-chartjs-2';


ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
  );
  
  export const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Chart.js Bar Chart',
      },
    },
    scales: {
        y: {
          type: 'linear',
          display: true,
          position: 'left',
          title: { display: true, text: 'Users', color: 'rgba(255, 99, 132, 0.5)' },
        },
        y1: {
          type: 'linear',
          display: true,
          position: 'right',
          title: { display: true, text: 'Prompts', color: 'rgba(53, 162, 235, 0.5)'},
          grid: {
            drawOnChartArea: false,
          },
        },
      },
  };

/**
 * A component that pulls usage stats from the API and displays them.
 *
 * @param {string} text - The source of the image to display.
 * @returns {JSX.Element} - A JSX element representing the image.
 */
const UsageStats = () => {

    const reportTypes = [
        { id: 1, name: "Chat Usage", description: "Analyze chat usage statistics", queryKey: UsageType.CHAT },
        { id: 2, name: "Image Usage", description: "Analyze image usage statistics", queryKey: UsageType.IMAGE },
        { id: 3, name: "All Usage", description: "Analyze all usage statistics", queryKey: UsageType.ALL },
    ];

    const today = new Date();

    const [groupList, setGroupList] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [limitToPreset, setLimitToPreset] = useState(true);
    const [groupSearch, setGroupSearch] = useState("");

    const [dateFrom, setDateFrom] = useState(new Date(today.getFullYear(), today.getMonth() - 3, today.getDate()));
    const [dateTo, setDateTo] = useState(new Date());
    const [selectedReportType, setSelectedReportType] = useState(reportTypes.find((r) => r.queryKey === UsageType.ALL));
    const [formValid, setFormValid] = useState(false);
    
    const [chartData, setChartData] = useState(null);
    const [chartDataFiltered, setChartDataFiltered] = useState(null);

    const [chartDataMonth, setChartDataMonth] = useState(null);
    const [chartDataMonthFiltered, setChartDataMonthFiltered] = useState(null);

    const [chartDataWeek, setChartDataWeek] = useState(null);
    const [chartDataWeekFiltered, setChartDataWeekFiltered] = useState(null);

    // top line stats
    const [totalUsers, setTotalUsers] = useState(0);
    const [totalQueries, setTotalQueries] = useState(0);
    const [maximumDailyPrompts, setMaximumDailyPrompts] = useState(0);
    const [maximumDailyUsers, setMaximumDailyUsers] = useState(0);
    const [averageDailyUsers, setAverageDailyUsers] = useState(0);
    const [averageDailyPrompts, setAverageDailyPrompts] = useState(0);
    const [averageDailyPromptsPerUser, setAverageDailyPromptsPerUser] = useState(0);

    // data filters
    const [includeTotalPrompts, setIncludeTotalPrompts] = useState(true);
    const [includeTotalUsers, setIncludeTotalUsers] = useState(true);
    const [includePromptsPerUser, setIncludePromptsPerUser] = useState(true);

    const topStats = [
        { id: 1, name: "Total Users", value: totalUsers },
        { id: 2, name: "Total Prompts", value: totalQueries },
        { id: 3, name: "Maximum Daily Users", value: maximumDailyUsers },
        { id: 4, name: "Maximum Daily Prompts", value: maximumDailyPrompts },
        { id: 5, name: "Avg. Daily Users", value: averageDailyUsers},
        { id: 6, name: "Avg. Daily Prompts / User", value: averageDailyPromptsPerUser },
        { id: 7, name: "Avg. Daily Prompts", value: averageDailyPrompts },
    ];

    const debounce = (callback, waitTime) => {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                callback(...args);
            }, waitTime);
        };
    };

    const handleIncludeTotalUsersChange = (e) => {
        setIncludeTotalUsers(e.target.checked);
    };

    const handleIncludeTotalPromptsChange = (e) => {
        setIncludeTotalPrompts(e.target.checked);
    };

    const handleIncludePromptsPerUserChange = (e) => {
        setIncludePromptsPerUser(e.target.checked);
    };

    const handleLimitToPreset = (e) => {
        setLimitToPreset(e.target.checked);
    };

    const handleGroupSearchChange = (e) => {
        setGroupSearch(e.target.value);
        console.log('changed search')
    };

    const handleGroupSearchChangeDebounced = debounce(handleGroupSearchChange, 500);

    const filterData = (chartData, includeTotalUsers, includeTotalPrompts, includePromptsPerUser) => {
        const filteredData = {
            labels: chartData.labels,
            datasets: chartData.datasets.filter((dataset) => {
                if (dataset.filter === 'include_total_users') {
                    return includeTotalUsers;
                }
                if (dataset.filter === 'include_total_prompts') {
                    return includeTotalPrompts;
                }
                if (dataset.filter === 'include_prompts_per_user') {
                    return includePromptsPerUser;
                }
                return true;
            }),
        }

        return filteredData;
    };

    const calculateSevenDayAverage = (data, valueColumnName) => {

        const movingAvg = data.reduce((acc, curr, idx, arr) => { 
            const date = curr.date;
            // const count = curr[valueColumnName];
            const lastSevenDays = arr.slice(Math.max(0, idx - 6), idx + 1);
            const sum = lastSevenDays.reduce((acc, curr) => acc + curr[valueColumnName], 0);
            const average = sum / lastSevenDays.length;
            acc.push({ date, average });
            return acc;
        }, []);

        // console.log(movingAvg);
        return movingAvg;
    };

    const generateDateRanges = (startDate, endDate) => {
        const weeklyRanges = [];
        const monthlyRanges = [];
      
        let currentDate = new Date(startDate.getTime());
        const lastDate = new Date(endDate.getTime());
      
        // Generate weekly ranges
        while (currentDate <= lastDate) {
          const weekStart = new Date(currentDate.getTime());
          const weekEnd = new Date(currentDate.getTime());
          weekEnd.setDate(weekEnd.getDate() + 6);
      
          weeklyRanges.push({
            start: new Date(new Date(weekStart.getTime()).setHours(0, 0, 0, 0)),
            end: new Date(new Date(Math.min(weekEnd.getTime(), lastDate.getTime())).setHours(23, 59, 59, 999))
          });
      
          currentDate.setDate(currentDate.getDate() + 7);
        }
      
        // Reset currentDate to startDate
        currentDate = new Date(startDate.getTime());
      
        // Generate monthly ranges
        while (currentDate <= lastDate) {
          const monthStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
          const monthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
      
          monthlyRanges.push({
            start: new Date(new Date(monthStart.getTime()).setHours(0, 0, 0, 0)),
            end: new Date(new Date(Math.min(monthEnd.getTime(), lastDate.getTime())).setHours(23, 59, 59, 999))
          });
      
          currentDate.setMonth(currentDate.getMonth() + 1);
        }
      
        return { weeklyRanges, monthlyRanges };
    }

    const getUsageStats = async () => {
        try {
            const usageType = selectedReportType.queryKey;
            const startDate = dateFrom.toISOString();
            const endDate = dateTo.toISOString();

            let response;
            if (selectedGroup?.id) {
                response = await UsageAPI.groupUsage(usageType, selectedGroup.id, startDate, endDate);
            } else {
                response = await UsageAPI.usage(usageType, startDate, endDate);
            }

            // add prompts per user to the data
            response.data.forEach((stat) => {
                stat.prompts_per_user = stat.total_users > 0 ? stat.total_queries / stat.total_users : 0;
            });

            // pull out the final row which is the total, and skews the chart if left in
            const totals = response.data.pop();

            // set top stats
            setTotalUsers(totals.total_users);
            setTotalQueries(totals.total_queries);
            setMaximumDailyPrompts(Math.max(...response.data.map((stat) => stat.total_queries)));
            setMaximumDailyUsers(Math.max(...response.data.map((stat) => stat.total_users)));
            setAverageDailyUsers(parseFloat(response.data.map((stat) => stat.total_users).reduce((acc, curr) => acc + curr, 0) / response.data.length).toFixed(1));
            setAverageDailyPrompts(parseFloat(totals.total_queries / response.data.length).toFixed(1));
            setAverageDailyPromptsPerUser(parseFloat(response.data.map((stat) => stat.prompts_per_user).reduce((acc, curr) => acc + curr, 0) / response.data.length).toFixed(1));

            // prepare chart data from raw data
            // raw data is an array of objects with date, total_users, and total_queries
            const labels = response.data.map((stat) => stat.date);
            const userData = response.data.map((stat) => stat.total_users);
            const promptData = response.data.map((stat) => stat.total_queries);
            const promptsPerUserData = response.data.map((stat) => stat.prompts_per_user);
            const avgUsers = calculateSevenDayAverage(response.data, 'total_users');
            const avgPrompts = calculateSevenDayAverage(response.data, 'total_queries');
            const avgPromptsPerUser = calculateSevenDayAverage(response.data, 'prompts_per_user');

            const preppedData = {
                labels: labels,
                datasets: [
                    {
                        type: 'bar',
                        label: 'Users',
                        filter: 'include_total_users',
                        data: userData,
                        yAxisID: 'y',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                    {
                        type: 'line',
                        label: '7 Day Average Users',
                        filter: 'include_total_users',
                        data: avgUsers.map((stat) => stat.average),
                        yAxisID: 'y',
                        borderColor: 'rgb(255, 99, 132)',
                        borderWidth: 2,
                        fill: false,
                    },
                    {
                        type: 'bar',
                        label: 'Prompts per User',
                        filter: 'include_prompts_per_user',
                        data: promptsPerUserData,
                        yAxisID: 'y',
                        backgroundColor: 'rgba(75, 192, 192, 0.5)',
                    },
                    {
                        type: 'line',
                        label: '7 Day Average Prompts per User',
                        filter: 'include_prompts_per_user',
                        data: avgPromptsPerUser.map((stat) => stat.average),
                        yAxisID: 'y',
                        borderColor: 'rgb(75, 192, 192)',
                        borderWidth: 2,
                        fill: false,
                    },
                    {
                        type: 'bar',
                        label: 'Prompts',
                        filter: 'include_total_prompts',
                        data: promptData,
                        yAxisID: 'y1',
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },
                    {
                        type: 'line',
                        label: '7 Day Average Prompts',
                        filter: 'include_total_prompts',
                        data: avgPrompts.map((stat) => stat.average),
                        yAxisID: 'y1',
                        borderColor: 'rgb(53, 162, 235)',
                        borderWidth: 2,
                        fill: false,
                    }
                ]
            };
            setChartData(preppedData);
            setChartDataFiltered(filterData(preppedData, includeTotalUsers, includeTotalPrompts, includePromptsPerUser))

            const ranges = generateDateRanges(dateFrom, dateTo);

            // Loop over each week and get the data
            (async () => {
                const promises = [];
                ranges.weeklyRanges.forEach((range) => {
                    if (selectedGroup?.id) {
                        promises.push(UsageAPI.groupUsage(usageType, selectedGroup.id, range.start.toISOString(), range.end.toISOString()));
                    } else {
                        promises.push(UsageAPI.usage(usageType, range.start.toISOString(), range.end.toISOString()));
                    }
                });
                const weeklyResults = await Promise.allSettled(promises);
                const weeklyData = weeklyResults.filter((r) => r.status === 'fulfilled').map((r) => r.value.data.pop());

                // add prompts per user to the data, along with deltas to prior period
                weeklyData.forEach((stat, i) => {
                    // change 'Overall for 2024-01-01 to 2024-01-07' to just '2024-01-01 to 2024-01-07'
                    stat.date = stat.date.replace('Overall for ', '');

                    stat.prompts_per_user = stat.total_users > 0 ? stat.total_queries / stat.total_users : 0;
                    if (i > 0) {
                        const prior = weeklyData[i - 1];
                        stat.users_delta = stat.total_users - prior.total_users;
                        stat.prompts_delta = stat.total_queries - prior.total_queries;
                        stat.prompts_per_user_delta = parseFloat(stat.prompts_per_user - prior.prompts_per_user).toFixed(2);
                        stat.users_delta_pct = prior.total_users > 0 ? parseFloat(((stat.total_users - prior.total_users) / prior.total_users) * 100).toFixed(2) : 0;
                        stat.prompts_delta_pct = prior.total_queries > 0 ? parseFloat(((stat.total_queries - prior.total_queries) / prior.total_queries) * 100).toFixed(2) : 0;
                        stat.prompts_per_user_delta_pct = prior.prompts_per_user > 0 ? parseFloat(((stat.prompts_per_user - prior.prompts_per_user) / prior.prompts_per_user) * 100).toFixed(2) : 0;
                    }
                });

                // setDeltaByWeek(weeklyData[weeklyData.length - 1])

                const weeklyLabels = weeklyData.map((stat) => stat.date);
                const weeklyUserData = weeklyData.map((stat) => stat.total_users);
                const weeklyPromptData = weeklyData.map((stat) => stat.total_queries);
                const preppedWeeklyData = {
                    labels: weeklyLabels,
                    datasets: [
                        {
                            type: 'bar',
                            label: 'Users',
                            filter: 'include_total_users',
                            data: weeklyUserData,
                            yAxisID: 'y',
                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        },
                        {
                            type: 'bar',
                            label: 'Prompts per User',
                            filter: 'include_prompts_per_user',
                            data: weeklyData.map((stat) => stat.prompts_per_user),
                            yAxisID: 'y',
                            backgroundColor: 'rgba(75, 192, 192, 0.5)',
                        },
                        {
                            type: 'bar',
                            label: 'Prompts',
                            filter: 'include_total_prompts',
                            data: weeklyPromptData,
                            yAxisID: 'y1',
                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        }
                    ],
                    priorPeriodDelta: weeklyData.length > 1 ? weeklyData[weeklyData.length - 1] : {}
                };
                console.log(preppedWeeklyData)
                setChartDataWeek(preppedWeeklyData);
                setChartDataWeekFiltered(filterData(preppedWeeklyData, includeTotalUsers, includeTotalPrompts, includePromptsPerUser))
            })();

            // Loop over each month and get the data
            (async () => {
                const promises = [];
                ranges.monthlyRanges.forEach((range) => {
                    if (selectedGroup?.id) {
                        promises.push(UsageAPI.groupUsage(usageType, selectedGroup.id, range.start.toISOString(), range.end.toISOString()));
                    } else {
                        promises.push(UsageAPI.usage(usageType, range.start.toISOString(), range.end.toISOString()));
                    }
                });
                const monthlyResults = await Promise.allSettled(promises);
                const monthlyData = monthlyResults.filter((r) => r.status === 'fulfilled').map((r) => r.value.data.pop());

                // add prompts per user to the data, along with deltas to prior period
                monthlyData.forEach((stat, i) => {
                    // change 'Overall for 2024-01-01 to '2024-01-31' to 'Jan 2024'
                    // change 01 to 15 to get the middle of the month, avoid timezone issue with 01 converting to prior day
                    stat.date = new Date(stat.date.replace('-01 ', '-15 ').split(' ')[2]).toLocaleDateString('en-US', { month: 'short', year: 'numeric' });

                    stat.prompts_per_user = stat.total_users > 0 ? stat.total_queries / stat.total_users : 0;
                    if (i > 0) {
                        const prior = monthlyData[i - 1];
                        stat.users_delta = stat.total_users - prior.total_users;
                        stat.prompts_delta = stat.total_queries - prior.total_queries;
                        stat.prompts_per_user_delta = parseFloat(stat.prompts_per_user - prior.prompts_per_user).toFixed(2);
                        stat.users_delta_pct = prior.total_users > 0 ? parseFloat(((stat.total_users - prior.total_users) / prior.total_users) * 100).toFixed(2) : 0;
                        stat.prompts_delta_pct = prior.total_queries > 0 ? parseFloat(((stat.total_queries - prior.total_queries) / prior.total_queries) * 100).toFixed(2) : 0;
                        stat.prompts_per_user_delta_pct = prior.prompts_per_user > 0 ? parseFloat(((stat.prompts_per_user - prior.prompts_per_user) / prior.prompts_per_user) * 100).toFixed(2) : 0;
                    }
                });

                const monthlyLabels = monthlyData.map((stat) => stat.date);
                const monthlyUserData = monthlyData.map((stat) => stat.total_users);
                const monthlyPromptData = monthlyData.map((stat) => stat.total_queries);
                const preppedMonthlyData = {
                    labels: monthlyLabels,
                    datasets: [
                        {
                            type: 'bar',
                            label: 'Users',
                            filter: 'include_total_users',
                            data: monthlyUserData,
                            yAxisID: 'y',
                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        },
                        {
                            type: 'bar',
                            label: 'Prompts per User',
                            filter: 'include_prompts_per_user',
                            data: monthlyData.map((stat) => stat.prompts_per_user),
                            yAxisID: 'y',
                            backgroundColor: 'rgba(75, 192, 192, 0.5)',
                        },
                        {
                            type: 'bar',
                            label: 'Prompts',
                            filter: 'include_total_prompts',
                            data: monthlyPromptData,
                            yAxisID: 'y1',
                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        }
                    ],
                    priorPeriodDelta: monthlyData.length > 1 ? monthlyData[monthlyData.length - 1] : {}
                };
                // console.log(preppedMonthlyData)
                setChartDataMonth(preppedMonthlyData);
                setChartDataMonthFiltered(filterData(preppedMonthlyData, includeTotalUsers, includeTotalPrompts, includePromptsPerUser))
            })();


        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        setFormValid(dateFrom && dateTo && (dateFrom <= dateTo) && (selectedReportType));
    }, [selectedReportType, dateFrom, dateTo, formValid, setFormValid]);

    useEffect(() => {
        const applyFilters = () => {
            if(chartData) {
                const filteredData = filterData(chartData, includeTotalUsers, includeTotalPrompts, includePromptsPerUser);
                setChartDataFiltered(filteredData);
            }
    
            if(chartDataWeek) {
                const filteredDataWeek = filterData(chartDataWeek, includeTotalUsers, includeTotalPrompts, includePromptsPerUser);
                setChartDataWeekFiltered(filteredDataWeek);
            }
    
            if(chartDataMonth) {
                const filteredDataMonth = filterData(chartDataMonth, includeTotalUsers, includeTotalPrompts, includePromptsPerUser);
                setChartDataMonthFiltered(filteredDataMonth);
            }
        }
        applyFilters();
    }, [includeTotalUsers, includeTotalPrompts, includePromptsPerUser, chartData, chartDataWeek, chartDataMonth]);

    useEffect(() => {
        UsageAPI.groups(groupSearch, limitToPreset).then((response) => {
            setGroupList(response.data);
        }).catch((error) => {
            console.error(error);
        });
    }, [groupSearch, limitToPreset]);

  return (
    <div class="flex flex-col h-screen">

        <div class="p-4 bg-hero-pattern">
            {/* <!-- First row content --> */}
            <img src={skechai_logo} alt="Skech AI" className="w-48"></img>
            <h2 class="text-lg font-bold text-white">Usage Statistics</h2>
        </div>

        <div class="flex-1 flex  overflow-hidden">
            <div class="flex-none bg-gray-200">
                {/* <!-- Left column content --> */}
                <div class="p-4">
                    <div className="flex flex-col items-center align-top">

                        <div className="flex flex-col text-gray-400 text-lg mt-1">
                            <p className="text-gray-400 text-lg mt-4">
                                <span className="text-gray-600">Date Range</span>
                            </p>

                            <div className="flex flex-col relative mt-2"><DatePicker selected={dateFrom} onChange={date => setDateFrom(date)} className="outline outline-1 rounded py-1 px-2"/><MdCalendarMonth style={{ position: "absolute", right: "10px", top: "4px", pointerEvents: "none"}} size="1.5em" /></div>
                            <div className="flex flex-col relative mt-2"><DatePicker selected={dateTo} onChange={date => setDateTo(date)} className="outline outline-1 rounded py-1 px-2"/><MdCalendarMonth style={{ position: "absolute", right: "10px", top: "4px", pointerEvents: "none"}} size="1.5em" /></div>

                            <p className="text-gray-400 text-lg mt-4">
                                <span className="text-gray-600">Model Type</span>
                            </p>
                            <select className="rounded outline outline-1 p-2" value={selectedReportType.queryKey} onChange={(e) => setSelectedReportType(reportTypes.find((rt) => rt.queryKey === e.target.value))}>
                                <option value="">Select Usage Type</option>
                                {reportTypes.map((reportType) => (
                                    <option key={reportType.queryKey} value={reportType.queryKey}>{reportType.name}</option>
                                ))}
                            </select>

                            <p className="text-gray-400 text-lg mt-4">
                                <span className="text-gray-600">Group</span>
                            </p>
                            <div className="flex flex-col">
                                <div><input type="checkbox" id="limit_to_preset" name="limit_to_preset" checked={limitToPreset} onChange={handleLimitToPreset}/> <label htmlFor="limit_to_preset">Preset List</label></div>
                                <div><label htmlFor="group_search">Search Groups</label> <input className="rounded outline outline-1 p-2 my-2" type="text" id="group_search" name="group_search" onChange={handleGroupSearchChangeDebounced} /></div>
                            </div>
                            <select className="rounded outline outline-1 p-2" value={selectedGroup?.id} onChange={(e) => setSelectedGroup(groupList.find((gr) => gr?.id === e.target.value))}>
                                <option value="">Everyone</option>
                                {groupList.map((group) => (
                                    <option key={group.id} value={group.id}>{group.name}</option>
                                ))}
                            </select>

                            <p className="text-gray-400 text-lg mt-4">
                                <span className="text-gray-600">Metrics</span>
                            </p>
                            <div className="flex flex-col">
                                <div><input type="checkbox" id="include_total_users" name="include_total_users" checked={includeTotalUsers} onChange={handleIncludeTotalUsersChange}/> <label htmlFor="include_total_users">Total Users</label></div>
                                <div><input type="checkbox" id="include_total_prompts" name="include_total_prompts" checked={includeTotalPrompts} onChange={handleIncludeTotalPromptsChange}/> <label htmlFor="include_total_prompts">Total Prompts</label></div>
                                <div><input type="checkbox" id="include_prompts_per_user" name="include_prompts_per_user" checked={includePromptsPerUser} onChange={handleIncludePromptsPerUserChange}/> <label htmlFor="include_prompts_per_user">Prompts per User</label></div>

                            </div>
                        </div>
                        <div className="flex justify-end items-end space-x-6">
                            <button type="button"disabled={!formValid} className="btn mt-4 inline-block bg-light-blue hover:bg-dark-blue text-white font-bold py-3 px-8 rounded-md" onClick={getUsageStats}>Go</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex-1 bg-gray-200 p-4 overflow-y-auto">
            {/* <!-- Second row content (scrollable) --> */}
                <div className="flex flex-row justify-between">
                    {topStats.map((stat) => (
                        <div key={stat.id} className="card w-42 bg-base-200 shadow-xl text-sm p-2">
                            <div className="card-body p-2">
                                <h2 className="card-title">{stat.value}</h2>
                                <p>{stat.name}</p>
                            </div>
                        </div>
                    ))}
                </div>

                {chartDataFiltered && (
                    <div className="card w-full bg-base-100 shadow-xl p-8 my-4">
                        <div className="card-body">
                            <h2 className="card-title">Daily</h2>
                            <Chart id="daily" className="h-80 w-full" data={chartDataFiltered} options={options} />
                        </div>
                    </div>
                )}


                {chartDataWeekFiltered && (
                    <div className="card w-full bg-base-100 shadow-xl p-8 my-4">
                        <div className="card-body">
                            <div className="flex flex-row justify-between">
                                <div>
                                    <h2 className="card-title">Weekly</h2>
                                </div>

                                <div className="flex flex-row">
                                    <div className="card w-42 bg-base-200 shadow-xl text-sm p-2">
                                        <div className="card-body p-2">
                                            <h2 className="card-title">{chartDataWeek.priorPeriodDelta.users_delta} / {chartDataWeek.priorPeriodDelta.users_delta_pct} %</h2>
                                            <p>Daily Users <br/>vs Prior Week</p>
                                        </div>
                                    </div>
                                    <div className="card w-42 bg-base-200 mx-8 shadow-xl text-sm p-2">
                                        <div className="card-body p-2">
                                            <h2 className="card-title">{chartDataWeek.priorPeriodDelta.prompts_per_user_delta} / {chartDataWeek.priorPeriodDelta.prompts_per_user_delta_pct} %</h2>
                                            <p>Daily Prompts / User <br/>vs Prior Week</p>
                                        </div>
                                    </div>
                                    <div className="card w-42 bg-base-200 shadow-xl text-sm p-2">
                                        <div className="card-body p-2">
                                            <h2 className="card-title">{chartDataWeek.priorPeriodDelta.prompts_delta} / {chartDataWeek.priorPeriodDelta.prompts_delta_pct} %</h2>
                                            <p>Daily Prompts <br/>vs Prior Week</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <Chart id="weekly" data={chartDataWeekFiltered} options={options} />
                        </div>
                    </div>
                )}

                {chartDataMonthFiltered && (
                    <div className="card w-full bg-base-100 shadow-xl p-8 my-4">
                        <div className="card-body">
                            <div className="flex flex-row justify-between">
                                <div>
                                    <h2 className="card-title">Monthly</h2>
                                </div>

                                <div className="flex flex-row">
                                    <div className="card w-42 bg-base-200 shadow-xl text-sm p-2">
                                        <div className="card-body p-2">
                                            <h2 className="card-title">{chartDataMonth.priorPeriodDelta.users_delta || '-'} / {chartDataMonth.priorPeriodDelta.users_delta_pct || '-'} %</h2>
                                            <p>Daily Users <br/>vs Prior Month</p>
                                        </div>
                                    </div>
                                    <div className="card w-42 bg-base-200 mx-8 shadow-xl text-sm p-2">
                                        <div className="card-body p-2">
                                            <h2 className="card-title">{chartDataMonth.priorPeriodDelta.prompts_per_user_delta || '-'} / {chartDataMonth.priorPeriodDelta.prompts_per_user_delta_pct || '-'} %</h2>
                                            <p>Daily Prompts / User <br/>vs Prior Month</p>
                                        </div>
                                    </div>
                                    <div className="card w-42 bg-base-200 shadow-xl text-sm p-2">
                                        <div className="card-body p-2">
                                            <h2 className="card-title">{chartDataMonth.priorPeriodDelta.prompts_delta || '-'} / {chartDataMonth.priorPeriodDelta.prompts_delta_pct || '-'} %</h2>
                                            <p>Daily Prompts <br/>vs Prior Month</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <Chart id="monthly" data={chartDataMonthFiltered} options={options} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    </div>
    
   
)};


export default UsageStats;
