
import React from 'react';
import skechers_logo_small from '../assets/skechers_logo_small.png';
import chat_dark from '../assets/chat_dark.png';

/**
 * A chat view component that displays a list of messages and a form for sending new messages.
 */

const GetStarted = (props) => {

  const messages = [
    { "modelType": "text", "example": "I want to share a brief summary of this contract with the CEO. Please provide an outline of key points in terms they would resonate with." },
    { "modelType": "text", "example": "I am generating product descriptions for shoes. Please generate a description of shoes with the following specifications..." },
    { "modelType": "text", "example": "Help me identify the most common issues reflected in this survey, including the root cause and potential solutions." },
    { "modelType": "image", "example": "Design a shoe riser for one shoe with a distressed wood base, metal shelf, and Skechers' branding." },
    { "modelType": "image", "example": "Design an acrylic sign with interior LED lights that shine through, etched Skechers logo, and faux wood base wrap." },
    { "modelType": "image", "example": "Design a creative in-store shoe display inspired by Snoop Dogg." },
  ];

  return (
    <main className="chatview__chatarea flex flex-col items-center">
      <div className="relative mt-5">
        <div className="w-32 h-32 bg-gray-200 rounded-full flex items-center justify-center">
        <img 
            src={skechers_logo_small} 
            alt="Logo" 
        />
        </div>
      </div>
      {/* Details before the chat */}
      <div id="details" className="mt-4 text-center">
        <h2 className="font-bold text-2xl leading-8 text-gray-700">Get Started</h2>
        <p className="font-normal mt-4 text-16 text-gray-700">
          Simply use the textbox to get started! 
          <br />
          Check out the Use Case Library on the left-hand side for prompt inspiration and guidance.
        </p>

        <div className="flex justify-center flex-wrap pt-8">

          {messages.filter((message) => message.modelType === props.modelType).map((message, index) => (
            <div key={index} className="w-263 h-234 rounded-md flex flex-col justify-center items-center m-8">
              <div className="w-60 h-60 bg-gray-100 rounded-lg">
                <img src={chat_dark} alt="Icon" width="40" height="40" className="block m-auto mt-5" />
                <div className="text-12 text-gray-700 text-center leading-5 mt-2 p-4">
                  {message.example}
                </div>
              </div>
            </div>
          ))}

        </div>
      </div>
    </main>
  );
};

export default GetStarted;
