import React, { useContext } from 'react';
import {useNavigate } from 'react-router-dom';
import { DisclaimerContext } from '../context/disclaimerContext';
import Modal from './Modal';

/**
 * A component that displays an image.
 *
 * @param {string} text - The source of the image to display.
 * @returns {JSX.Element} - A JSX element representing the image.
 */
const Disclaimer = (props) => {
  const navigate = useNavigate();

  const [disclaimerAccepted, setDisclaimerAccepted] = useContext(DisclaimerContext);

  const handleAccept = (e) => {
    e.preventDefault();
    setDisclaimerAccepted(true);
  };

  const handleDecline = () => {
    navigate('/');
  }

  return (
    <Modal title="" modalOpen={!disclaimerAccepted} useClose={false} classes={'disclaimer-modal'}>
      <div className="flex items-center justify-center bg-gray-100">
        <div className="bg-white rounded-xl container mx-auto">
          <div className="text-center text-3xl font-bold text-gray-700 mb-4">
            SkechAI
          </div>
          <div className="border-t-2 border-gray-300 mt-4 mb-8"></div>
          <p className="text-gray-400 text-lg">
            Skechers’ SkechAI is our version of public GPT models within hosted securely Skechers cloud. It's a safer way to leverage all of the groundbreaking features of public GPT models in a responsible and secure way. Everything you submit stays within our cloud.
          </p>
          <p className="text-gray-400 text-lg mt-4">
            This is a Generative Artificial Intelligence (AI) model that uses machine learning and probability to produce images and human-like text. <span className="text-gray-600 font-bold">The output generated from this technology solution is not intended to be a substitute for professional advice and will require professional judgement and skepticism to verify that the responses are accurate and appropriate.</span>
          </p>
          <p className="text-gray-400 text-lg mt-4">
            Your use of this technology solution must comply with relevant company policies. It is your responsibility to protect confidential information in accordance with applicable legal requirements, professional standards, and contractual obligations. Failure to comply with these policies may result in serious professional, legal, and/or reputational risk.
          </p>
          <p className="text-gray-600 text-lg font-bold mt-4">
            <strong>By accessing this technology solution, you acknowledge and agree to the above.</strong>
          </p>
          <div className="flex justify-end items-end space-x-6">
            <button className="mt-4 inline-block bg-blue-800 hover:bg-blue-800 text-white font-bold py-3 px-8 rounded-md" onClick={handleAccept}>
              Accept
            </button>
            <button className="mt-4 ml-2 inline-block bg-gray-400 hover:bg-gray-400 text-white font-bold py-3 px-8 rounded-md" onClick={handleDecline}>
              Decline
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
};

export default Disclaimer;
