import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderMobile from '../Common/Mobile/HeaderMobile';
import SideBarMobile from '../Common/Mobile/SideBarMobile';
import MobileConfigContainer from '../MobileConfigContainer';
import TextConfig from '../ModelConfig/Text';
import ConfigSettingsMobile from '../Common/Mobile/ConfigSettingsMobile';

const UseCaseLibraryMobile = ({ filteredUseCases, newUseCase, modelType }) => {
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isConfigOpen, setIsConfigOpen] = useState(false);
  const navigate = useNavigate();

  const handleModelTypeChange = (newModelType) => {
    navigate(`/${newModelType}/usecases`);
    setDropdownOpen(false);
  };

  const uiState = {
    modelType: modelType,
    sideBarOpen: sideBarOpen,
    setSideBarOpen: setSideBarOpen,
    onModelTypeChange: handleModelTypeChange,
    dropdownOpen: dropdownOpen,
    setDropdownOpen: setDropdownOpen,
    isConfigOpen: isConfigOpen,
    setIsConfigOpen: setIsConfigOpen,
  };

  return (
    <div className='flex flex-col h-screen'>
      <HeaderMobile uiState={uiState} showSettings={false} />
      <SideBarMobile modelType={modelType} uiState={uiState} />
      <div className="flex-1 overflow-y-auto bg-white p-4">
        <div className="mobile-use-case-header">
          <h1 className="mobile-use-case-title">Use Case Library</h1>
          <h2 className="mobile-use-case-subtitle">Welcome to Skechers' GPT Resource Center</h2>
          <p className="mobile-use-case-description">
            This page contains Quick Reference Guides on use cases that have been built 
            out through the Skechers' Generative AI Pilot. Click on any of the use cases below 
            to download step by step instructions.</p>
          <p className="mobile-use-case-description">
            If you have any additional questions, please contact</p>
        </div>
        <div className="mobile-use-case-grid">
          {filteredUseCases.map((useCase) => (
            <div key={useCase.key} className="cursor-pointer" onClick={() => newUseCase(useCase.key)}>
              <div className="mobile-use-case-card">
                <img src={useCase.imageName} alt='' className="mobile-use-case-image" />
                <div className="mobile-use-case-title-container">
                  <p className="mobile-use-case-card-title">{useCase.title}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {isConfigOpen && (
        <MobileConfigContainer uiState={uiState}>
          <ConfigSettingsMobile uiState={uiState} />
          <TextConfig />
        </MobileConfigContainer>
      )}
    </div>
  );
};

export default UseCaseLibraryMobile;