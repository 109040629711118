import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from '../Modal';
import DatePicker from "react-datepicker";
import { MdCalendarMonth, MdOutlineSettings } from "react-icons/md";
import "react-datepicker/dist/react-datepicker.css";
import formats from '../../utils/formatting';

const reportTypes = [
  {
    id: 'diagnostic_query',
    name: 'Call Log Details',
    description: 'Get detailed listing of call logs for the selected scope, including date/time, duration, sentiment, root causes and reasons, and ID.',
    queryKey: 'diagnostic_query',
    apiParams: {
      query_name: 'diagnostic_query',
      summarize_result: false,
      // "model": Optional[str] = 'anthropic.claude-3-sonnet-20240229-v1:0'
    },
    display: {
      options: {
        columns: [ {name: "Date", align: "right"}, {name: "Duration", align: "right"}, {name: "Duration_MMSS", align: "right"}, "Length", "Old Length", "Sentiment", "Root Cause 1", "Reason 1", "Root Cause 2", "Reason 2", "Root Cause 3", "Reason 3", "Root Cause 4", "Reason 4", "Root Cause 5", "Reason 5", "ID", "Agent", "Customer"],
      },
      transform: (data) => {
        return data.map((item) => {
          const m = {
            datetime: new Date(item.datetime).toLocaleDateString() + ' ' + new Date(item.datetime).toLocaleTimeString(),
            duration: item.duration,
            duration_mmss: formats.millisToMinutesAndSeconds(item.duration),
            length: item.duration <= 300000 ? 'short' : item.duration > 420000 ? 'long' : 'medium',
            length_old: item.duration <= 180000 ? 'short' : item.duration > 480000 ? 'long' : 'medium',
            sentiment: item.overall_sentiment,
            rootCause1: item.root_cause_1_category,
            reason1: item.root_cause_1_reason,
            rootCause2: item.root_cause_2_category,
            reason2: item.root_cause_2_reason,
            rootCause3: item.root_cause_3_category,
            reason3: item.root_cause_3_reason,
            rootCause4: item.root_cause_4_category,
            reason4: item.root_cause_4_reason,
            rootCause5: item.root_cause_5_category,
            reason5: item.root_cause_5_reason,
            id: item.contact_id,
            agent: item.agent_id,
            customer: item.customer_id,
          }
          return m;
        });
      }
    },
  },
  {
    id: 'alternative_questions',
    name: 'Alternative Questions',
    description: 'Continue to chat window to ask a question about the transcripts selected.',
    queryKey: 'ad_hoc_query',
    isAdHoc: true,
  },
];

/**
 * A component that displays an image.
 *
 * @param {string} text - The source of the image to display.
 * @returns {JSX.Element} - A JSX element representing the image.
 */
const RootCauseDiagnostics = (props) => {

  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [selectedReportType, setSelectedReportType] = useState(null);
  const [selectedReportSubType, setSelectedReportSubType] = useState(null);
  const [formValid, setFormValid] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const params = useParams();

  const toUtcDateBegin = (date) => {
    const YYYY = date.getFullYear();
    const MM = date.getMonth();
    const DD = date.getDate();
    return new Date(Date.UTC(YYYY, MM, DD, 0, 0, 0, 0));
  };

  const toUtcDateEnd = (date) => {
    const YYYY = date.getFullYear();
    const MM = date.getMonth();
    const DD = date.getDate();
    return new Date(Date.UTC(YYYY, MM, DD, 23, 59, 59, 999));
  };

  const submitQuery = () => {
    let report = selectedReportSubType || selectedReportType;

    // attach selected parameters
    if(report.apiParams) {
      report.apiParams.params = {
        start_date: toUtcDateBegin(dateFrom),
        end_date: toUtcDateEnd(dateTo),
        // include any preset parameters specified for the report
        ...report.presetParams 
      }
    }

    setModalOpen(false);

    const query = report.isAdHoc ? "" : `${report.name}, from ${new Date(dateFrom).toLocaleDateString()} to ${new Date(dateTo).toLocaleDateString()}`;
    props.submitReportQuery(report.apiParams, query, !!report.isAdHoc, report.display);

  };

  const cancelQuery = () => {
    setModalOpen(false);
    if (!params.sessionId) {
      navigate('/text/usecases');
    }
  };

  useEffect(() => {
    setFormValid(dateFrom && dateTo && (dateFrom <= dateTo) && (selectedReportType?.queryKey || selectedReportSubType?.queryKey));
    console.log('form valid', !!formValid, dateFrom, dateTo, selectedReportType?.id, selectedReportSubType?.id);
  }, [selectedReportType, selectedReportSubType, dateFrom, dateTo, formValid, setFormValid]);

  return (
    <div>
      <Modal title="" modalOpen={modalOpen} useClose={false} classes={'use-case-params-modal'}>
        <div className="flex items-center justify-center bg-gray-100">
          <div className="bg-white rounded-xl container mx-auto">
            <div className="text-center text-3xl font-bold text-gray-700 mb-4">
              Root Cause Analysis Generator
            </div>
            <p className="text-gray-400 text-lg">
              <span className="font-bold text-xl text-gray-600">Purpose:</span> Use Skechers to analyze customer support call transcripts for root causes and preventative actions.
            </p>
            <p className="text-gray-400 text-lg mt-4">
              <span className="font-bold text-xl text-gray-600">Instructions:</span> Initiate your root cause analysis by selecting your desired time period along with the specific type of analysis you want to undertake. Click "Go" to begin.
            </p>

            <div className="border-t-2 border-gray-300 mt-4 mb-4"></div>

            <p className="text-gray-400 text-lg mt-4">
            <span className="font-bold text-xl text-gray-600">Scope of Analysis</span>
            </p>
            <p className="text-gray-400 text-lg mt-4">
            <span className="text-gray-600">Date Range</span>
            </p>
            <p className="flex flex-row text-gray-400 text-lg mt-1">
              <div className="flex flex-row mr-16"><DatePicker selected={dateFrom} onChange={date => setDateFrom(date)} className="outline outline-1 rounded py-1 px-2"/><MdCalendarMonth style={{ marginLeft: "-34px", marginTop: "4px", zIndex: 2, pointerEvents: "none"}} size="1.5em" /></div>
              <div className="flex flex-row"><DatePicker selected={dateTo} onChange={date => setDateTo(date)} className="outline outline-1 rounded py-1 px-2"/><MdCalendarMonth style={{ marginLeft: "-34px", marginTop: "4px", zIndex: 2, pointerEvents: "none"}} size="1.5em" /></div>
            </p>
            <p className="text-gray-600 text-lg font-bold mt-4">
              Analysis Type
            </p>
            <div className="flex justify-start pl-6 items-end space-x-6 flex-wrap">
              {reportTypes.map((reportType) => (
                <div key={reportType.id} className={`mt-3 inline-block hover:bg-blue-400 text-slate-800 rounded-md1 h-40 w-52 p-4 ${reportType.id === selectedReportType?.id ? "bg-blue-400" : "bg-gray-300"}`} onClick={() => {setSelectedReportType(reportType); setSelectedReportSubType(null);}}>
                  <div className="font-bold text-center">
                    {reportType.name}
                  </div>
                  <div className="mt-3 text-xs text-center">
                    {reportType.description}
                  </div>
                </div>
              ))}
            </div>
            <div className={selectedReportType?.subTypes ? "visible": "invisible"}>
              <p className="text-gray-600 text-lg font-bold mt-4">
                Analysis Sub-Type
              </p>
              <div className="flex justify-start pl-6 items-end space-x-6 flex-wrap">
                {selectedReportType?.subTypes?.map((reportType) => (
                  <div key={reportType.id} className={`mt-3 inline-block hover:bg-blue-400 text-slate-800 rounded-md1 h-40 w-52 p-4 ${reportType.id === selectedReportSubType?.id ? "bg-blue-400" : "bg-gray-300"}`} onClick={() => setSelectedReportSubType(reportType)}>
                    <div className="font-bold text-center">
                      {reportType.name}
                    </div>
                    <div className="mt-3 text-xs text-center">
                      {reportType.description}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex justify-end items-end space-x-6">
              <button type="button" className="mt-4 inline-block bg-light-blue hover:bg-dark-blue text-white font-bold py-3 px-8 rounded-md" onClick={cancelQuery}>Cancel</button>
              <button type="button"disabled={!formValid} className="btn mt-4 inline-block bg-light-blue hover:bg-dark-blue text-white font-bold py-3 px-8 rounded-md" onClick={submitQuery}>Go</button>
            </div>
          </div>
        </div>
      </Modal>
      <button type="button" style={{ right: "8px", bottom: "148px" }} className="btn absolute inline-block bg-light-blue hover:bg-dark-blue text-white font-bold py-3 px-6 rounded-xl" onClick={() => setModalOpen(true)}><MdOutlineSettings size="1.5em" /></button>
    </div>
  )
};

export default RootCauseDiagnostics;
export { reportTypes };
