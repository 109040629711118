import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import SideBarMobile from './SideBarMobile';
import HeaderMobile from './HeaderMobile';
import { FavoritesAPI } from '../../../api/favoritesAPI';
import deleteIcon from '../../../assets/delete_icon.png';
import editIcon from '../../../assets/edit_icon.png';
import MobileConfigContainer from '../../MobileConfigContainer';
import TextConfig from '../../ModelConfig/Text';
import ConfigSettingsMobile from '../../Common/Mobile/ConfigSettingsMobile';

const ConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="confirmation-modal-overlay">
      <div className="confirmation-modal-content">
        <p className="confirmation-modal-text">Are you sure that you want to delete this prompt? This action cannot be undone.</p>
        <div className="confirmation-modal-buttons">
          <button onClick={onClose} className="confirmation-modal-button confirmation-modal-button-cancel">
            Cancel
          </button>
          <button onClick={onConfirm} className="confirmation-modal-button confirmation-modal-button-delete">
            Delete Prompt
          </button>
        </div>
      </div>
    </div>
  );
};

const SavedPromptsMobile = ({ modelType }) => {
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isConfigOpen, setIsConfigOpen] = useState(false);
  const [savedPrompts, setSavedPrompts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [promptToDelete, setPromptToDelete] = useState(null);
  const [deletingPromptIds, setDeletingPromptIds] = useState([]);

  const navigate = useNavigate();

  const fetchSavedPrompts = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await FavoritesAPI.getFavorites();
      setSavedPrompts(response.data || []);
    } catch (error) {
      console.error('Error fetching saved prompts:', error);
      setSavedPrompts([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSavedPrompts();
  }, [fetchSavedPrompts]);

  const handleDeleteClick = (promptId) => {
    setPromptToDelete(promptId);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = useCallback(async () => {
    if (promptToDelete) {
      try {
        setDeletingPromptIds(prev => [...prev, promptToDelete]);
        await FavoritesAPI.delFavorite(promptToDelete);
        setSavedPrompts(prev => prev.filter(prompt => prompt.id !== promptToDelete));
      } catch (error) {
        console.error('Error deleting prompt:', error);
      } finally {
        setIsDeleteModalOpen(false);
        setPromptToDelete(null);
        setDeletingPromptIds(prev => prev.filter(id => id !== promptToDelete));
      }
    }
  }, [promptToDelete]);

  const handleModelTypeChange = useCallback((newModelType) => {
    navigate(`/${newModelType}/prompts`);
    setDropdownOpen(false);
  }, [navigate]);

  const uiState = {
    modelType,
    sideBarOpen,
    setSideBarOpen,
    onModelTypeChange: handleModelTypeChange,
    dropdownOpen,
    setDropdownOpen,
    isConfigOpen,
    setIsConfigOpen,
  };

  return (
    <div className='saved-prompts-container'>
      <SideBarMobile modelType={modelType} uiState={uiState} />
      <div className="saved-prompts-main">
        <HeaderMobile uiState={uiState} showSettings={false} />
        <div className="saved-prompts-content">
          <div className="saved-prompt-header">
            <h1 className="saved-prompt-title">Saved Prompts</h1>
          </div>

          {isLoading ? (
            <div className="saved-prompts-loading">Loading saved prompts...</div>
          ) : savedPrompts.length === 0 ? (
            <div className="saved-prompts-empty">No saved prompts found.</div>
          ) : (
            savedPrompts.map((prompt) => (
              <div 
                key={prompt.id} 
                id={prompt.id} 
                className={`saved-prompt-item ${deletingPromptIds.includes(prompt.id) ? 'deleting' : ''}`}
              >
                <div className="saved-prompt-content" onClick={() => navigate(`/${modelType}/prompts/${prompt.id}`)}>
                  <h2 className="saved-prompt-name">{prompt.name}</h2>
                  <p className="saved-prompt-description">{prompt.prompt}</p>
                </div>
                <div className="saved-prompt-footer">
                  <p className="saved-prompt-date">
                    {moment.utc(prompt.created_at).local().format('MMM Do YYYY, h:mm a')}
                  </p>
                  <div className="saved-prompt-actions">
                    {/*
                    <button className="saved-prompt-action-button">
                      <img src={editIcon} alt="Edit" className="saved-prompt-action-icon" />
                    </button>
                    */}
                    <button 
                      onClick={() => handleDeleteClick(prompt.id)}
                      className="saved-prompt-action-button"
                      disabled={deletingPromptIds.includes(prompt.id)}
                    >
                      <img src={deleteIcon} alt="Delete" className="saved-prompt-action-icon" />
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      {isConfigOpen && (
        <MobileConfigContainer uiState={uiState}>
          <ConfigSettingsMobile uiState={uiState} />
          <TextConfig />
        </MobileConfigContainer>
      )}
      <ConfirmationModal 
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteConfirm}
      />
    </div>
  );
};

export default SavedPromptsMobile;