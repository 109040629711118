import React from "react";

const ConfigContainer = ({ children, uiState }) => {
	const { isConfigOpen, setIsConfigOpen } = uiState
	return (
		<div className="mobile-drawer">
			<div
				className={"modelparams h-screen overflow-y-auto " + (!isConfigOpen ? "scale-0 hidden" : "")}
			>
				<div className="bg-white">
					{children}
				</div>
			</div>
		</div>

	);
}

export default ConfigContainer;

