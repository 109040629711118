import React from 'react';
import SideBarDesktop from '../Common/Desktop/SideBarDesktop';
import { FaTrash, FaUndo, FaPencilAlt } from "react-icons/fa";
import moment from 'moment';

const ChatHistoryDesktop = ({
  chatSessions,
  loadSession,
  removeSession,
  cancelRemoveSession,
  deletingSessionIds,
  handleSaveSession,
  modelType
}) => {
  return (
    <div className='flex transition duration-500 ease-in-out'>
      <SideBarDesktop modelType={modelType} />
      <div className="w-full h-screen">
        <div className="chathistory__header bg-white shadow-md text-center text-2xl font-bold py-4">
          Chat History
        </div>
        <main className='flex flex-grow flex-col overflow-hidden relative' style={{"maxHeight":"calc(100% - 4rem)"}}>
          <div className="w-full overflow-y-scroll flex flex-col">
            {chatSessions.map((session, index) => (
              <div key={session.session_id} id={session.session_id} className="session-box cursor-pointer border-solid border-2 border-gray-200 rounded-lg p-4 mx-10 my-4">
                <div onClick={() => { loadSession(session.session_id) }} key={index}>
                  <div className="date font-bold text-right text-sm">{moment.utc(session.last_updated).local().format('MMMM Do YYYY, h:mm a')}</div>
                  <h2 className="font-bold">{session.title}</h2>
                  <p className="text-sm">{session.description}</p>
                </div>
                <div>
                  <div className="flex flex-row align-right justify-end">
                    {deletingSessionIds.indexOf(session.session_id) >= 0 ? (
                      <button onClick={() => cancelRemoveSession(session.session_id)}><FaUndo /></button>
                    ) : (
                      <div>
                        <button className="pr-4 cursor-pointer" onClick={() => removeSession(session.session_id)}><FaTrash /></button>
                        <button onClick={() => handleSaveSession(session.description, session.title, session.session_id)}><FaPencilAlt /></button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </main>
      </div>
    </div>
  );
};

export default ChatHistoryDesktop;