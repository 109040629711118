import React, { useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SessionContext } from '../../context/sessionContext';
import { isMobile } from 'react-device-detect';
import TextChatDesktop from './TextChatDesktop';
import TextChatMobile from './TextChatMobile';

const queryClient = new QueryClient();


const TextChat = () => {
  const params = useParams();
  const navigate = useNavigate();  
  const [ , , txtSessionId] = useContext(SessionContext);
  
  // if we navigated here without a session ID, if there is a current one then set it on the URL and use it
  useEffect(() => {
    if (!params.sessionId && txtSessionId) {
      params.sessionId = txtSessionId;
      navigate(`/text/${txtSessionId}`, { replace: true });
    }
  }, [navigate, params, txtSessionId]);

  return (
    <QueryClientProvider client={queryClient}>
      {isMobile ? <TextChatMobile/> : <TextChatDesktop/>}
    </QueryClientProvider>
  );
}

export default TextChat;