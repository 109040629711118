import React from 'react';
import ConfigContainer from '../ConfigContainer';
import TextConfig from '../ModelConfig/Text';
import SideBarDesktop from '../Common/Desktop/SideBarDesktop';
import ChatView from '../ChatView';
import Disclaimer from '../Disclaimer';
import { useState } from 'react';

const TextChatDesktop = () => {
  const [isConfigOpen, setIsConfigOpen] = useState(false);
  const uiState = {
    isConfigOpen: isConfigOpen,
    setIsConfigOpen: setIsConfigOpen,
  }

  return (
      <div className='flex transition duration-500 ease-in-out'>
          <SideBarDesktop modelType="text"/>
          <ChatView modelType="text"/>
          <ConfigContainer uiState={uiState}>
            <TextConfig />
          </ConfigContainer>
          <Disclaimer />
      </div>
  );
}

export default TextChatDesktop;