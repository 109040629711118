import { createContext, useState } from 'react';

/**
 * ChatContext is a context object that is used to share collection of messages
 * between components
 */
const DisclaimerContext = createContext(false);

/**
 * ChatContextProvider is a functional component that serves as a provider for the ChatContext.
 * It provides the ChatContext to the components within its subtree.
 *
 * @param {Object} props - The properties passed to the component.
 * @returns {JSX.Element} A ChatContext.Provider element.
 */
const DisclaimerContextProvider = (props) => {
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);

  return (
    <DisclaimerContext.Provider value={[disclaimerAccepted, setDisclaimerAccepted]}>
      {props.children}
    </DisclaimerContext.Provider>
  );
};

export { DisclaimerContext, DisclaimerContextProvider };
