import React, { useState, useEffect } from 'react';
import Modal from '../Modal';
import { FaTimes } from "react-icons/fa";
import { FavoritesAPI } from '../../api/favoritesAPI';
import { SessionHistoryAPI } from '../../api/chatAPI';
import { useParams } from 'react-router-dom';

/*
    Component takes Session Name and Session Content. Session Content, Session Name auto populated after pressing Pencil, but adjustable. Then we can save the updates
    to history

    INPUTS:
        sessionContent: The initial content for the save modal to show (from the bookmarked chat)
        sessionTitle: The initial Title of the session
        initialModalPos: The position of Modal (open or close) from parent
        openModal: the function to update a parent component value in child (in this case modal pos)
        sessionID: the specific sessionID for the session (used by API)
        updateSessionData: Passes function to update the frontend tile initially in the parent. (no reload required)


*/

const EditChatHistory = ({ sessionContent, sessionTitle, initialModalPos, openModal, sessionID, updateSessionData }) => {
    const [sessionName, setSessionName] = useState('');
    const [content, setContent] = useState(sessionContent || '');
    const [prevSessionContent, setPrevSessionContent] = useState('');
    const [error, setError] = useState(false);
    const params = useParams();



    const handleExit = (e) => {
        openModal(false);
        setSessionName('');
    }

    const handleSendSession = (e) => {
        if (sessionName == "") {
            setError(true);
            return;
        }

        SessionHistoryAPI.setSessionTitle(sessionID, sessionName)
        SessionHistoryAPI.setSessionDescription(sessionID, content)
        updateSessionData(sessionID, sessionName, content);

        setError(false);
        openModal(false);
        setSessionName('');
    }

    useEffect(() => {
        if (initialModalPos && (sessionContent !== prevSessionContent || sessionTitle !== sessionName)) {
            setSessionName(sessionTitle);
            setContent(sessionContent);
            setPrevSessionContent(sessionContent);
        }
    }, [initialModalPos, sessionContent, sessionTitle]);

    return (
        <Modal title="" modalOpen={initialModalPos} useClose={false} classes='save-session-modal' useRelative={false}>
            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-1000 w-[35rem] h-[37.5rem] rounded-3xl flex flex-col justify-between">
                <div className="bg-dark-blue w-[50rem] h-[50rem] rounded-3xl flex flex-col justify-between">
                    <div className="flex items-center justify-between px-6 py-4">
                        <div className="flex items-center text-xl text-white">
                            <span>Edit Session Information</span>
                        </div>
                        <button className="text-white" onClick={handleExit}> <FaTimes /> </button>
                    </div>

                    <div className="flex flex-col px-6 flex-grow">
                        <div className="flex flex-col mb-4">
                            <label htmlFor="sessionName" className="text-white mb-2">
                                Session Name
                            </label>
                            <textarea
                                id="sessionName"
                                className={`bg-black text-white rounded-lg p-2 resize-none box-border ${error && sessionName.trim() === '' ? 'border-[0.5px] border-2 border-red-500 box-border' : 'border-[0.5px] border-transparent'
                                    }`}
                                value={sessionName}
                                onChange={(e) => setSessionName(e.target.value)}
                            />
                        </div>

                        <div className="flex flex-col mb-4 flex-grow">
                            <label htmlFor="sessionContent" className="text-white mb-2">
                                Session Description
                            </label>
                            <textarea
                                id="sessionContent"
                                className="bg-black text-white rounded-lg p-2 resize-none flex-grow"
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                            />
                        </div>

                        <div className="flex justify-end pb-5">
                            <button className="bg-light-blue text-white rounded-lg px-5 py-2 capitalize" onClick={handleSendSession}>
                                Update Session
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default EditChatHistory;
