import React from 'react';
import textIcon from '../../../assets/dropdown_text_icon.png';
import imageIcon from '../../../assets/dropdown_image_icon.png';
import checkIcon from '../../../assets/dropdown_check_icon.png';

const ModelTypeDropdown = ({ modelType, onModelTypeChange }) => {
  return (
    <div className="model-type-dropdown">
      <div className="model-type-option" onClick={() => onModelTypeChange('text')}>
        <div className="model-type-icon">
          <img src={textIcon} alt="Text Generation" />
        </div>
        <div className="model-type-content">
          <h3>Text Generation</h3>
          <p>This platform meets all your text needs, allowing you to create, process, and generate natural language conversations, ideas, and complex information.</p>
        </div>
        {modelType === 'text' && <img src={checkIcon} alt="Checked" className="check-icon" />}
      </div>

      <div className="dropdown-separator"></div>

      <div className="model-type-option" onClick={() => onModelTypeChange('image')}>
        <div className="model-type-icon">
          <img src={imageIcon} alt="Image Generation" />
        </div>
        <div className="model-type-content">
          <h3>Image Generation</h3>
          <p>This platform transforms your text into incredible visuals, perfect for designing concepts and bringing your creative vision to life.</p>
        </div>
        {modelType === 'image' && <img src={checkIcon} alt="Checked" className="check-icon" />}
      </div>
    </div>
  );
};

export default ModelTypeDropdown;