import React, { useState } from 'react';

/*
    This Prompt Class stores the name of the prompt and its content inside. That way we can access the 2 components 
    easily in an array. Both are type string. 

    UpdatePromptName : Update the name with a new name
    UpdatePromptContent : Update the associated prompt with a new prompt

*/

class Prompt { 
    constructor(initialPromptName, initialPromptContent, promptID = null) {
        this.name = initialPromptName; 
        this.content = initialPromptContent;
        this.promptID = promptID; 
    }

    updatePromptName(newName) { 
        this.name = newName;
    }
    updatePromptContent(newContent) {
        this.content = newContent;
    }

    updatePromptID(newPromptID) {
        this.promptID = newPromptID;
    }
    
}
export default Prompt;
