import React from 'react'
import bot from '../assets/avatar_bot.png';

const Thinking = () => {
  return (
    <div className='message'>
      <div className='message__wrapper flex'>
        <div className="message__pic h-20">
          <div className='avatar'>
              <div className='w-8 border mt-2 rounded-full'>
                <img src={bot} alt='bot pic' />
              </div>
            </div>
        </div>
        <div className='text-left message__createdAt'>
          <div className="message__thinking h-20 ml-8 pt-8">
            thinking...
          </div>
        </div>
      </div>
    </div>
  )
}

export default Thinking