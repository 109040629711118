import { useNavigate } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import config from './config';
import Routes from './components/Routes';

const oktaAuth = new OktaAuth(config.oidc);
const App = () => {

  const navigate = useNavigate();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  }

  return (

    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <div>
        <Routes />
      </div>
    </Security>
  );
}; 

export default App;
