import React from 'react';
import skechai from '../../../assets/Dark_BG_SkechAI_logo_sm.png';
import SideBar from '../SideBar';

/**
 * A sidebar component that displays a list of nav items and a toggle
 * for switching between light and dark modes.
 *
 * @param {Object} props - The properties for the component.
 */
const SideBarMobile = (props) => {
  return (
    <section className={`sidebar sidebar-sub ${!props.uiState.sideBarOpen ? '-translate-x-full' : 'translate-x-0'}`}>
      <div className='sidebar__app-bar'>
        <div onClick={() => props.uiState.setSideBarOpen(!props.uiState.sideBarOpen)} className='sidebar__app-logo'>
            <img src={skechai} className='skechai-logo' alt='' />
        </div>
        <h1 className='sidebar__app-title'>
          <div className="logo-title">{props.modelType === 'image' ? 'Image Model' : 'Text Model'}</div>
        </h1>
      </div>
      <SideBar modelType={props.modelType} open={true} isMobile={true} />
    </section>
  );
};

export default SideBarMobile;
