import { api, API_ENDPOINT_STREAM, getToken, setToken} from "./configs/axiosConfig";
import { defineCancelApiObject } from "./configs/axiosUtils";
export const AnnouncementAPI = {
  
  // simply displays announcements calling /announcements end point
  getAnnouncements: async function (cancel = false) {
    try {
      const response = await api.request({
        url: `/announcements`,
        method: "GET",
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },
  updateToken: (token) => {
    setToken(token);
  },

  // marks an announcement in UI as read. Takes announcement ID as input. 
  markAsRead: async function (announcementID, cancel = false) {
    try {
      const response = await api.request({
        url: `/announcements/mark_read`,
        method: "POST",
        data: {
          announcement_id: announcementID,
        },
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },
  updateToken: (token) => {
    setToken(token);
  },
  

}
const cancelApiObject = defineCancelApiObject(AnnouncementAPI)