import { createContext, useState } from 'react';
import { DefaultImageConfig } from '../components/ModelConfig/DefaultImageConfig';
const ImageConfigContext = createContext({});


/**
  * @param {Object} props - The properties passed to the component.
 * @returns {JSX.Element} A ChatContext.Provider element.
 */
const ImageConfigContextProvider = (props) => {

  const [negativePrompts, setNegativePrompts] = useState(DefaultImageConfig.negativePrompts);
  const [height, setHeight] = useState(DefaultImageConfig.height);
  const [width, setWidth] = useState(DefaultImageConfig.width);
  const [cfgScale, setCfgScale] = useState(DefaultImageConfig.cfgScale);
  const [clipGuidancePreset, setClipGuidancePreset] = useState(DefaultImageConfig.clipGuidancePreset)
  const [sampler, setSampler] = useState(DefaultImageConfig.sampler);
  const [samples, setSamples] = useState(DefaultImageConfig.samples);
  const [seed, setSeed] = useState(DefaultImageConfig.seed);
  const [steps, setSteps] = useState(DefaultImageConfig.steps);
  const [stylePreset, setStylePreset] = useState(DefaultImageConfig.stylePreset);
  const [aspectRatio, setAspectRatio] = useState(DefaultImageConfig.aspectRatio);
  const [imageStrength, setImageStrength] = useState(DefaultImageConfig.imageStrength);
  // model: str = "stability.stable-diffusion-xl-v1"

  return (
    <ImageConfigContext.Provider value={[
      negativePrompts, setNegativePrompts,
      height, setHeight,
      width, setWidth,
      cfgScale, setCfgScale,
      clipGuidancePreset, setClipGuidancePreset,
      sampler, setSampler,
      samples, setSamples,
      seed, setSeed,
      steps, setSteps,
      stylePreset, setStylePreset,
      aspectRatio, setAspectRatio,
      imageStrength, setImageStrength,
    ]}>
      {props.children}
    </ImageConfigContext.Provider>
  );
};

export { ImageConfigContext, ImageConfigContextProvider };

