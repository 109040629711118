import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ImageAPI } from '../api/chatAPI';
import { isMobile } from 'react-device-detect';

/**
 * A component that displays an image.
 *
 * @param {string} text - The source of the image to display.
 * @returns {JSX.Element} - A JSX element representing the image.
 */
const ImageResponse = (props) => {
  
  return (
    <div className={!isMobile ? 'message__wrapper image__group__wrapper' : ''}>
      {props.raw.artifacts[0].map((artifact) => (
        <div className='image__wrapper' key={artifact.image_id}>
          <Image ImageID={artifact.image_id} />
        </div>
      ))}
    </div>
  );
};

const Image = (props) => {

  const params = useParams();
  const sessionId = params.sessionId;
  const [base64, setBase64] = useState(null);

  useEffect(() => {
    const getImage = async () => {
      const response = await ImageAPI.getImage(sessionId, props.ImageID);
      setBase64(response.data);
    }
    getImage();
  }, [props.ImageID, sessionId]);

  return (
    <img
      className={'message__img'}
      src={`data:image/png;base64, ${base64}`}
      alt='generated from prompt'
      loading='lazy'
    />
  );
};

export default ImageResponse;