import React, { useState, useEffect } from 'react';
import { AnnouncementAPI } from '../api/announcementAPI';
import ReactMarkdown from 'react-markdown';


const AnnouncementList = () => {
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await AnnouncementAPI.getAnnouncements();
        setAnnouncements(response.data);
      } catch (error) {
        console.error('Error fetching announcements:', error);
      }
    };

    fetchAnnouncements();
  }, []);

  // notifications list object that displays notifications from Mongo. Button to mark as read. 

  return (
    <div className="bg-dark-blue w-[50rem] rounded-3xl flex flex-col justify-between pt-2 min-h-[37rem] max-h-[20rem] overflow-y-auto pb-[2rem] flex-shrink">
      <div className="p-4">
        <h2 className="text-2xl font-bold text-white mb-4">Notifications</h2>
        <ul className="flex-1 overflow-y-auto">
          {announcements.map((announcement, index) => (
            <li
              key={index}
              className="bg-white rounded-lg shadow-md mb-4 p-4"
            >
              <ReactMarkdown className="text-lg font-bold mb-2">
                {announcement.title}
              </ReactMarkdown>
              <ReactMarkdown className="mb-4">
                {announcement.content}
              </ReactMarkdown>
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => AnnouncementAPI.markAsRead(announcement.id)}
              >
                Mark as Read
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AnnouncementList;