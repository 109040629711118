import React, { useState, useEffect } from 'react';
import Modal from '../Modal';
import { FaTimes, FaCheck } from "react-icons/fa";
import { FavoritesAPI } from '../../api/favoritesAPI';

/*
    Component takes Prompt Name and Prompt Content. Prompt Content auto populated after pressing bookmark, but adjustable. Then we can send 
    a prompt, which will auto populate the inout bar. Used MongoDB Axios calls in favoritesAPI to adjust API after save.

    INPUTS:
        promptContent: The initial content for the save modal to show (from the bookmarked chat)
        initialModalPos: The position of Modal (open or close) from parent
        updateFunc: the function to update a parent component value in child (in this case modal pos)

*/

const SavePromptMobile = ({ savePromptModalProps }) => {
    const { promptContent, initialModalPos, updateFunc } = savePromptModalProps;
    const [promptName, setPromptName] = useState('');
    const [content, setContent] = useState(promptContent || '');
    const [prevPromptContent, setPrevPromptContent] = useState('');
    const [error, setError] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);


    const handleExit = (e) => {
        updateFunc(false);
        setPromptName('');
        setIsSubmit(false);
    }

    const handleSendPrompt = (e) => {
        if (promptName == "") {
            setError(true);
            return;
        }
        FavoritesAPI.addFavorite(promptName, content);
        setError(false);
        setIsSubmit(true);
    }

    useEffect(() => {
        if (initialModalPos && promptContent !== prevPromptContent) {
            setPromptName('');
            setContent(promptContent);
            setPrevPromptContent(promptContent);

        }
    }, [promptContent]);

    return (
        <Modal title="" modalOpen={initialModalPos} useClose={false} classes='save-prompt-modal' useRelative={false}>
            <div className="general-save-prompt">
                <div className="general-sub-save-prompt">
                    <div className="save-prompt-exit-align">

                        <button className="text-white" onClick={handleExit}> <FaTimes /> </button>
                    </div>

                    <div className="save-prompt-general-content">
                        <div className="save-prompt-bottom-padding">
                            <label htmlFor="promptName" className="text-white mb-2">
                                <strong> Prompt Name </strong> (Editable)
                            </label>
                            <textarea
                                id="promptName"
                                className={`save-area-mobile ${error && promptName.trim() === ''
                                        ? 'save-area-mobile-error'
                                        : 'save-area-mobile-no-error'
                                    } ${isSubmit ? 'bg-gray-400' : 'bg-white'}`}
                                onChange={(e) => !isSubmit && setPromptName(e.target.value)}
                                readOnly={isSubmit}
                                value={promptName}
                            />
                        </div>

                        <div className="save-prompt-bottom-padding flex-grow">
                            <label htmlFor="promptContent" className="text-white mb-2">
                                <strong> Prompt Content </strong> (Editable)
                            </label>
                            <textarea
                                id="promptContent"
                                className={`save-area-content ${isSubmit ? 'bg-gray-400' : 'bg-white'}`}
                                value={content}
                                onChange={(e) => !isSubmit && setContent(e.target.value)}
                                readOnly={isSubmit}
                            />
                        </div>

                        <div className="flex justify-end pb-5">
                            {!isSubmit ? (
                                <button className="bg-light-blue save-prompt-button-general" onClick={handleSendPrompt}>
                                    Save Prompt
                                </button>
                            ) :
                                <div
                                    className="bg-gray-700 save-prompt-button-general save-prompt-button-align"
                                    onClick={handleSendPrompt}
                                >
                                    <span>Saved</span>
                                    <FaCheck />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SavePromptMobile;
