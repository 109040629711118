import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { ChatAPI, SessionHistoryAPI } from '../../api/chatAPI';
import { SessionContext } from '../../context/sessionContext';
import { ChatContext } from '../../context/chatContext';
import SideBarDesktop from '../Common/Desktop/SideBarDesktop';
import moment from 'moment';
import { FaTrash, FaUndo, FaPencilAlt } from "react-icons/fa";
import EditChatHistory from './EditChatHistory';
import ChatHistoryDesktop from './ChatHistoryDesktop';
import ChatHistoryMobile from './ChatHistoryMobile';

const ChatHistory = (props) => {
  const [chatSessions, setChatSessions] = useState([]);
  const [, setImgSessionId, , setTxtSessionId, , setImgSessionTitle, , setTxtSessionTitle] = useContext(SessionContext);
  const [, , , clearMessages] = useContext(ChatContext);
  const [deletingSessionIds, setDeletingSessionIds] = useState([]);
  const [removeList, setRemoveList] = useState([]);
  const [timerEvent, setTimerEvent] = useState(0);

  const [showSaveSessionModal, setShowSaveSessionModal] = useState(false);
  const [selectedSessionContent, setSelectedSessionContent] = useState('');
  const [selectedSessionTitle, setSelectedSessionTitle] = useState('');
  const [currSessionId, setCurrSessionID] = useState(null);

  const navigate = useNavigate();

  const handleSaveSession = (sessionContent, sessionTitle, sessionID) => {
    setSelectedSessionContent(sessionContent);
    setSelectedSessionTitle(sessionTitle);
    setCurrSessionID(sessionID);
    setShowSaveSessionModal(true);
  };

  const handleCloseModal = () => {
    setShowSaveSessionModal(false);
    setSelectedSessionContent('');
    setSelectedSessionTitle('');
  };

  const updateSessionData = (sessionId, updatedTitle, updatedDescription) => {
    const updatedSessions = chatSessions.map((session) => {
      if (session.session_id === sessionId) {
        return { ...session, title: updatedTitle, description: updatedDescription };
      }
      return session;
    });
    setChatSessions(updatedSessions);
  };

  useEffect(() => {
    const modelType = props.modelType;
    ChatAPI.getHistory(modelType).then(async (response) => {
      setChatSessions(response.data.sessions);
    });
  }, [props.modelType]);

  const loadSession = (sessionId) => {
    props.modelType === 'image' ? setImgSessionId(sessionId) : setTxtSessionId(sessionId);
    const title = chatSessions.find((session) => session.session_id === sessionId).title;
    props.modelType === 'image' ? setImgSessionTitle(title) : setTxtSessionTitle(title);
    navigate(`/${props.modelType}/${sessionId}`, { replace: true });
  }

  useEffect(() => {
    // little hacky, using incrementing timerEvent to trigger useEffect for deleting sessions / cancel deleting sessions
  }, [timerEvent, deletingSessionIds]);

  const removeSession = useCallback((sessionId) => {
    if (!sessionId) return;

    let updatedIds = deletingSessionIds;
    updatedIds.push(sessionId);
    setDeletingSessionIds(updatedIds);

    const removeTimer = setTimeout(() => {
      SessionHistoryAPI.deleteSession(sessionId).then(async (response) => {
        ChatAPI.getHistory(props.modelType).then(async (response) => {
          document.getElementById(sessionId).parentNode.removeChild(document.getElementById(sessionId));
          let updatedIds = deletingSessionIds;
          updatedIds.splice(deletingSessionIds.indexOf(sessionId), 1);
          setDeletingSessionIds(updatedIds);
        });
      });
    }, 5000);

    let updateRemoveList = removeList;
    updateRemoveList.push({ "sessionId": sessionId, timer: removeTimer });
    setRemoveList(updateRemoveList);
    document.getElementById(sessionId).classList.add("removing");
    document.getElementById(sessionId).classList.add("bg-red-100");
    setTimerEvent(timerEvent + 1);

  }, [deletingSessionIds, removeList, props.modelType, timerEvent]);

  const cancelRemoveSession = useCallback((sessionId) => {
    if (!sessionId) return;

    const removeTimer = removeList.find((item) => item.sessionId === sessionId).timer;
    clearTimeout(removeTimer);

    let updatedRemoveList = removeList;
    updatedRemoveList.splice(removeList.indexOf(removeList.find((item) => item.sessionId === sessionId)), 1);
    setRemoveList(updatedRemoveList);

    let updatedIds = deletingSessionIds;
    updatedIds.splice(deletingSessionIds.indexOf(sessionId), 1);
    setDeletingSessionIds(updatedIds);
    document.getElementById(sessionId).classList.remove("removing");
    document.getElementById(sessionId).classList.remove("bg-red-100");
    setTimerEvent(timerEvent + 1);
  }, [removeList, deletingSessionIds, timerEvent]);

  /* blunter methods to remove / restore session, reloading chat histor from server each time */
  // const removeSession = (sessionId) => {
  //   SessionHistoryAPI.deleteSession(sessionId).then(async (response) => {
  //     ChatAPI.getHistory(props.modelType).then(async (response) => {
  //       setChatSessions(response.data.sessions);
  //     });
  //   });
  // }

  // const restoreSession = (sessionId) => {
  //   SessionHistoryAPI.restoreSession(sessionId).then(async (response) => {
  //     ChatAPI.getHistory(props.modelType).then(async (response) => {
  //       setChatSessions(response.data.sessions);
  //     });
  //   });
  // }

  const commonProps = {
    chatSessions,
    loadSession,
    removeSession,
    cancelRemoveSession,
    deletingSessionIds,
    handleSaveSession,
    modelType: props.modelType,
  };

  return (
    <>
      {isMobile ? (
        <ChatHistoryMobile {...commonProps} />
      ) : (
        <ChatHistoryDesktop {...commonProps} />
      )}
      <EditChatHistory
        sessionContent={selectedSessionContent}
        sessionTitle={selectedSessionTitle}
        initialModalPos={showSaveSessionModal}
        openModal={handleCloseModal}
        sessionID={currSessionId}
        updateSessionData={updateSessionData}
      />
    </>
  );
};

export default ChatHistory;

