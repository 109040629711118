import React, { useState, useContext, useEffect } from 'react';
import skechai from '../../../assets/Dark_BG_SkechAI_logo_sm.png'
import brain from '../../../assets/Dark_BG_SkechAI_brain.png'
import { MdMenu } from 'react-icons/md';
import SideBar from '../SideBar';
/**
 * A sidebar component that displays a list of nav items and a toggle
 * for switching between light and dark modes.
 *
 * @param {Object} props - The properties for the component.
 */
const SideBarDesktop = (props) => {
  const [open, setOpen] = useState(true);

  function handleResize() {
    window.innerWidth <= 840 ? setOpen(false) : setOpen(true);
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  
    return () => {
     window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section className={`sidebar ${!open && 'minimized'} ${props.modelType === 'image' ? 'bg-blue-700' : 'bg-blue-950'}`}>
      <div className='sidebar__app-bar'>
        <div onClick={() => setOpen(!open)} className={`sidebar__app-logo`}>
            <img src={brain} className={"skx-logo " + (open && "hidden")} alt='' />
            <img src={skechai} className="skechai-logo" alt='' />
        </div>
        <h1 className={`sidebar__app-title ${!open && 'scale-0 hidden'}`}>
          <div className="logo-title">{props.modelType === 'image' ? 'Image Model' : 'Text Model'}</div>
        </h1>
        <div className={`sidebar__btn-close`} onClick={() => setOpen(!open)}>
          {!open && (
            <MdMenu className='sidebar__btn-icon' />
          )}
        </div>
      </div>
      {<SideBar modelType={props.modelType} open={open}/>}
    </section>
  );
};

export default SideBarDesktop;
