import React, { useState, useEffect, useRef } from 'react';
import { FaTrash, FaTimes, FaCaretDown } from "react-icons/fa";
import Modal from '../Modal';
import { FavoritesAPI } from '../../api/favoritesAPI';


/*
    This class creates a DropdownMenu. There is a search bar that searches for different things in the dropdown.
    Deletion can be done by pressing the trash can icon. 

    INPUTS:
        initialOptions: Array of Prompts
    
    Update Array : Get Array of prompts from mongo and replace Array. 
    Delete Element : Delete array of prompts from mongo and delete associated component in Array

*/

const SelectPromptMobile = ({ promptMenuProps }) => {
    const [options, setOptions] = useState([]);
    const menuRef = useRef(null);
    const [hoveredOption, setHoveredOption] = useState(null);
    const {initialOptions, initialDropDown, updateFunc, updateStateFunc} = promptMenuProps;


    useEffect(() => {
        setOptions(initialOptions);
    }, [initialOptions]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                updateFunc(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [updateFunc]);

    const handleOptionClick = (option) => {
        updateStateFunc(option.content)
        updateFunc(false);
    };

    return (
        <div className={`fixed bottom-20 mb-2 left-1/2 transform -translate-x-1/2 transition-all duration-300 z-[9999] ${initialDropDown ? 'opacity-100 scale-100' : 'opacity-0 scale-95 pointer-events-none'}`} ref={menuRef}
        >
            <div className="bg-dark-blue w-[90vw] max-w-md h-[60vh] rounded-lg shadow-lg flex flex-col">
                <div className="flex justify-end p-2">
                    <button onClick={() => { updateFunc(false) }} className="text-white hover:text-gray-300">
                        <div class="text-sm mt-1 pr-4">
                            <span class="underline cursor-pointer">Edit Save Prompts</span>
                        </div>            
                    </button>
                </div>
                <div className="h-px bg-blue-800 my-2" />

                <div className="flex-grow overflow-y-auto">
                    <ul id="optionsList" className="text-white px-4">
                        {options.map((option, index) => (
                            <React.Fragment key={option.promptID}>
                                <li
                                    className={`flex flex-col p-3 rounded-md cursor-pointer transition-colors duration-200 
                                ${hoveredOption === option ? 'bg-[#1e2a3a]' : 'transparent'}`}
                                    onMouseEnter={() => setHoveredOption(option)}
                                    onMouseLeave={() => setHoveredOption(null)}
                                    onClick={() => handleOptionClick(option)}
                                >
                                    <div className="flex justify-between items-center w-full">
                                        <span className="overflow-hidden text-ellipsis whitespace-nowrap max-w-[20rem]">
                                            {option.name}
                                        </span>
                                    </div>
                                    <p className="text-xs mt-1 overflow-hidden text-ellipsis whitespace-nowrap max-w-[20rem]">
                                        {option.content}
                                    </p>
                                </li>
                                {index < options.length - 1 && (
                                    <div className="h-px bg-blue-800 my-2" />
                                )}
                            </React.Fragment>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default SelectPromptMobile;
