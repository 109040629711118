import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SessionContext } from '../../context/sessionContext';
import { ChatContext } from '../../context/chatContext';
import UseCases from './UseCases';
import { isMobile } from 'react-device-detect';
import UseCaseLibraryDesktop from './UseCaseLibraryDesktop';
import UseCaseLibraryMobile from './UseCaseLibraryMobile';

const UseCaseLibrary = (props) => {
  const [, setImgSessionId, , setTxtSessionId, , , , , userInfo] = useContext(SessionContext);
  const [, , , clearMessages] = useContext(ChatContext);
  const navigate = useNavigate();

  const filteredUseCases = UseCases.filter((useCase) => {
    const targetType = useCase.modelType === props.modelType;
    const targetEnvs = useCase.environments || [];
    const targetRoles = useCase.roles || [];
    const inTargetRoles = targetRoles.length === 0 || targetRoles.find(role => userInfo?.skechaiRoles?.includes(role));
    const inTargetEnv = (targetEnvs.length === 1 && targetEnvs[0] === '*') || targetEnvs.includes(process.env.REACT_APP_ENVIRONMENT);
    return targetType && (process.env.REACT_APP_ENVIRONMENT === "dev" || (inTargetEnv && inTargetRoles));
  });

  const newUseCase = (key) => {
    props.modelType === 'image' ? setImgSessionId(null) : setTxtSessionId(null);
    navigate(`/${props.modelType}/usecase/${key}`, { replace: true });
  }

  const commonProps = {
    filteredUseCases,
    newUseCase,
    modelType: props.modelType,
  };

  return isMobile ? (
    <UseCaseLibraryMobile {...commonProps} />
  ) : (
    <UseCaseLibraryDesktop {...commonProps} />
  );
};

export default UseCaseLibrary;