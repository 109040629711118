import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SessionContext } from '../../context/sessionContext';
import home from '../../assets/home.png';
import book from '../../assets/book.png';
import chat from '../../assets/chat.png';
import save_prompts from '../../assets/bookmark.png';
import image_model from '../../assets/image_model.png';
import text_model from '../../assets/text_model.png';

/**
 * A sidebar component that displays a list of nav items and a toggle
 * for switching between light and dark modes.
 *
 * @param {Object} props - The properties for the component.
 */
const SideBar = (props) => {
  const [, setImgSessionId, , setTxtSessionId, , setImgSessionTitle, , setTxtSessionTitle] = useContext(SessionContext);
  const navigate = useNavigate(); 

  const clearChat = () => {
    props.modelType === 'image' ? setImgSessionId(null) : setTxtSessionId(null);
    props.modelType === 'image' ? setImgSessionTitle("New Project") : setTxtSessionTitle("New Chat");
    navigate(`/${props.modelType}`, { replace: true });
  };

  return (
    <div className="sidebar_container">
      <button onClick={clearChat} className="btn sidebar__new-chat bg-white rounded-lg capitalize text-center text-black hover:bg-slate-300">
        <h1 className={`${!props.open && 'hidden'}`}>{props.modelType === 'image' ? 'New Project' : 'New Chat'}</h1>
        <h1 className={`${props.open && 'hidden'}`}>+</h1>
      </button>
      <div className="nav">
        <ul className="menu text-xl">
          <li>
            <Link to={`/${props.modelType}/usecases`}>
              <img src={book} alt=''/>
              <span className={`${!props.open && 'hidden'}`}>Use Case Library</span>
            </Link>
          </li>
          <li>
            <Link to={`/${props.modelType}/history`}>
              <img src={chat} alt=''/>
              <span className={`${!props.open && 'hidden'}`}>Chat History</span>
            </Link>
          </li>
          {props.isMobile && (
            <li>
              <Link to={`/${props.modelType}/prompts`}>
                <img src={save_prompts} alt='' className="nav-icon" />
                <span className={`${!props.open && 'hidden'}`}>Saved Prompts</span>
              </Link>
            </li>
          )}
        </ul>
      </div>

      <div className='nav__bottom'>
        <ul className={`menu ${props.open ? "w-56" : "w-[3.9rem]"} rounded-box text-xl`}>
          <li>
            <Link to={props.modelType === 'image' ? '/text' : '/image'}>
              <img src={props.modelType === 'image' ? text_model : image_model} alt='' />
              <span className={`${!props.open && 'hidden'}`}>{props.modelType === 'image' ? 'Text Model' : 'Image Model'}</span>
            </Link>
          </li>
          <li>
            <Link to="/">
              <img src={home} alt='' />
              <span className={`${!props.open && 'hidden'}`}>Home</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
