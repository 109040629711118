import React, { useState } from 'react';
import MobileConfigContainer from '../MobileConfigContainer';
import SideBarMobile from '../Common/Mobile/SideBarMobile';
import ChatView from '../ChatView';
import Disclaimer from '../Disclaimer';
import ImageConfig from '../ModelConfig/Image';
import skechAILogo from '../../assets/Light_BG_SkechAI_logo.png'
import { Settings } from 'react-ionicons';
import ConfigSettingsMobile from '../Common/Mobile/ConfigSettingsMobile';


const ImageChatMobile = () => {
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [isConfigOpen, setIsConfigOpen] = useState(false);
  const uiState = {
    isConfigOpen: isConfigOpen,
    setIsConfigOpen: setIsConfigOpen,
    sideBarOpen: sideBarOpen,
    setSideBarOpen: setSideBarOpen,

  }

  return (
    <div className='config-header'>
      <SideBarMobile modelType="image" uiState={uiState} />
      <ChatView modelType="image" uiState={uiState} />
      {isConfigOpen && (
        <MobileConfigContainer uiState={uiState}>
          <ConfigSettingsMobile uiState={uiState} />
          <ImageConfig />
        </MobileConfigContainer>
      )}
      <Disclaimer />
    </div>
  );
}

export default ImageChatMobile;
