import React from 'react';
import { Link } from 'react-router-dom';
import skechers_logo_large from '../../assets/skechers_logo_large.png';
import skechai_logo from '../../assets/Dark_BG_SkechAI_logo_sm.png';

/**
 * A component that displays an image.
 *
 * @param {string} text - The source of the image to display.
 * @returns {JSX.Element} - A JSX element representing the image.
 */
const LearnMore = () => {
  return (
      <div className="flex flex-col min-h-screen">
        <header className="sticky z-50 top-0 p-4 flex items-center bg-hero-pattern text-white">
          <div className ="flex items-center justify-center">
            <img src={skechers_logo_large} alt="Skechers Logo" width="160px" className="my-10 mr-4"/>
            <img src={skechai_logo} alt="SkechAI Logo" width="300px"/>
          </div>
        </header>
        <div className="flex flex-grow">
          <div className="w-4/12 min-w-fit max-w-fit h-auto flex flex-col p-14 bg-neutral-200 text-left">
            <h1 className="text-3xl font-bold w-96 mb-4 ml-4">About The SkechAI Generative Pre-trained Transformer (GPT) Models</h1>
            <p className="text-xl mb-4 w-96 ml-4">With SkechAI, Skechers set out to establish a foundational operating model that will help facilitate responsible and secure practices for adoption and scale of generative AI capabilities, and accelerate experimentation and innovation.</p>
            <div className="nav">
              <ul className="menu text-xl">
                <li> <Link to="/text" className = "relative block rounded w-96 h-8 mb-4 ml-4 mt-10 bg-neutral-500 text-white text-base flex items-center justify-center">Launch Text Model</Link></li>
                <li><Link to="/image" className = "relative block rounded w-96 h-8 mb-4 ml-4 bg-neutral-500 text-white text-base flex items-center justify-center">Launch Image Model</Link></li>
                <li><Link to="https://skx.service-now.com/sp?id=nr_emp_taxonomy_topic&topic_id=d4a7480047ad42503198d6e8536d43cd" target="skechai_sole" className = "relative block rounded w-96 h-8 mb-4 ml-4 bg-neutral-500 text-white text-base flex items-center justify-center">SkechAI in SOLE</Link></li>
                <li><Link to="https://skx.service-now.com/$viewer.do?sysparm_stack=no&sysparm_sys_id=ea2bb32b87f546500b90b95f8bbb359c" target="skechai_usage" className = "relative block rounded w-96 h-8 mb-4 ml-4 bg-neutral-500 text-white text-base flex items-center justify-center">Acceptable Usage Policy</Link></li>
                {/* <li><Link to="/help" className = "relative block rounded w-96 h-8 mb-4 ml-4 bg-neutral-500 text-white text-base flex items-center justify-center">Launch Help Page</Link></li> */}
              </ul>
            </div>
          </div>
          <div className="container bg-white text-center py-6 w-3/5 flex flex-grow flex-col ml-16 mr-16">
            <div className="text-left mt-8"> 
              <h1 className="text-xl font-bold">Generative AI</h1>
              <h2 className="text-xl"> Generative AI is a machine learning technique that trains models to create new and original content such as text, or images. By analyzing vast amounts of data, the model can produce new content that follows similar patterns and characteristics to the original data.</h2>
            </div>
            <div className="text-left mt-6"> 
              <h1 className="text-xl font-bold">Text Generation Model</h1>
              <h2 className="text-xl">Designed to cater to your textual needs, this platform allows users to interact, process, and generate text for various applications. Whether you're looking to create natural language conversations, expand on ideas, or simplify complex information, this is the platform for you.</h2>
            </div>
            <div className="text-left mt-6"> 
              <h1 className="text-xl font-bold">Image Generation Model</h1>
              <h2 className="text-xl"> Unleash your imagination with our advanced AI-powered solution that translates your textual input into stunning visual content. Perfect for designing concepts, exploring new ideas, or bringing your creative vision to life, this platform offers an intuitive and seamless way to generate images based on your text descriptions.</h2>
            </div>
            {/* <div className="text-left mt-6"> 
              <h1 className="text-xl font-bold">Questions?</h1>
              <h2 className="text-xl"> Please contact ______.</h2>
            </div> */}
          </div>
        </div>
      </div>
  );
};

export default LearnMore;
