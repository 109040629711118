import React, { useState } from 'react';
import MobileConfigContainer from '../MobileConfigContainer';
import TextConfig from '../ModelConfig/Text';
import SideBarMobile from '../Common/Mobile/SideBarMobile';
import ChatView from '../ChatView';
import Disclaimer from '../Disclaimer';
import skechAILogo from '../../assets/Light_BG_SkechAI_logo.png'
import { Settings } from 'react-ionicons';
import ConfigSettingsMobile from '../Common/Mobile/ConfigSettingsMobile';

const TextChatMobile = () => {

  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [isConfigOpen, setIsConfigOpen] = useState(false);
  const uiState = {
    isConfigOpen: isConfigOpen,
    setIsConfigOpen: setIsConfigOpen,
    sideBarOpen: sideBarOpen,
    setSideBarOpen: setSideBarOpen,
  }

  return (
    <div className='config-header'>
      <SideBarMobile modelType="text" uiState={uiState} />
      <ChatView modelType="text" uiState={uiState} />
      {isConfigOpen && (
        <MobileConfigContainer uiState={uiState}>
          <ConfigSettingsMobile uiState={uiState} />
          <TextConfig />
        </MobileConfigContainer>
      )}
      <Disclaimer />
    </div>
  );
}

export default TextChatMobile;
