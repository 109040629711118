import { api, setToken } from "./configs/axiosConfig";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const AuthAPI = {
  login: async function (userId, password, cancel = false) {
    try {
      const response = await api.request({
        url: `/auth/`,
        method: "POST",
        // responseType: 'application/json',
        data: {
          username: userId,
          password: password
        },
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      // returning the data returned by the API
      return response;
    } catch (error) {
      throw error;
    }
  },

  updatePassword: async function (userId, password, newPassword, cancel = false) {
    try {
      const response = await api.request({
        url: `/password`,
        method: "POST",
        // responseType: 'application/json',
        data: {
          username: userId,
          password: password,
          new_password: newPassword
        },
        // retrieving the signal value by using the property name
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      // returning the data returned by the API
      return response;
    } catch (error) {
      throw error;
    }
  },

  getUserGroups: async function (cancel = false) {
    try {
      const response = await api.request({
        url: `/user/groups`,
        method: "GET",
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  getUserRoles: async function (cancel = false) {
    try {
      const response = await api.request({
        url: `/user/roles`,
        method: "GET",
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  udpateToken: (token) => {
    setToken(token);
  },

}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(AuthAPI)