import React, { useState } from "react";
import model_config from '../assets/model_config.png';

const ConfigContainer = ({ children, uiState}) => {
	const {isConfigOpen, setIsConfigOpen} = uiState

	return (
		<div className="relative flex shrink flex-col border-l-8 border-dark-blue">
			<div
				className={"modelparams h-screen overflow-y-auto " + (!isConfigOpen ? "scale-0 hidden" : "")}
			>
				{/* Content that slides in/out */}
				<div className="bg-white">
					{children}
				</div>
			</div>
			<div
				className="modelparams__toggle absolute cursor-pointer top-[50%] text-black"
				onClick={() => setIsConfigOpen(!isConfigOpen)}
			><img src={model_config} alt='toggle model configuration' className="modelparams__img pointer-events-none" /></div>
		</div>

	);
}

export default ConfigContainer;

