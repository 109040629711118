import React from 'react';
import SideBarDesktop from '../Common/Desktop/SideBarDesktop';

const UseCaseLibraryDesktop = ({ filteredUseCases, newUseCase, modelType }) => {
  return (
    <div className='flex transition duration-500 ease-in-out'>
      <SideBarDesktop modelType={modelType} />
      <div className="w-full overflow-y-scroll h-screen overflow-x-hidden">
        <div className="use-case-library-header">
          Use Case Library
        </div>
        <div className="use-case-library-welcome">
          <h1 className="text-2xl font-bold mb-2">Welcome to Skechers' Use Case Library</h1>
          <p className="use-case-library-text">This library contains use cases that have been built out through the Skechers' SkechAI</p>
          <p className="use-case-library-text">Click on one of these use cases to get started.</p>
        </div>
        <div className="use-case-grid">
          {filteredUseCases.map((useCase) => (
            <div className="cursor-pointer" key={useCase.key} onClick={() => newUseCase(useCase.key)}>
              <div className="use-case-card">
                <div className="use-case-image-container">
                  <img src={useCase.imageName} alt='' className="w-full h-half" />
                </div>
                <div className="use-case-title-container">
                  <p className="use-case-title">{useCase.title}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UseCaseLibraryDesktop;