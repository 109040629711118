import React from 'react';

// handle; OAuthError: User is not assigned to the client application.
// handle; AuthSdkError: Could not load PKCE codeVerifier from storage. This may indicate the auth flow has already completed or multiple auth flows are executing concurrently.

const Loading = () => {
  return (
    <h3 id='loading-icon'>Loading...</h3>
  );
};

export default Loading;