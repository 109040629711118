import React, { memo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SessionHistoryAPI } from '../../api/chatAPI';
import ChatMessageDesktop from './Desktop/ChatMessageDesktop';
import ChatMessageMobile from './Mobile/ChatMessageMobile';
import { isMobile } from 'react-device-detect';

const ChatMessage = memo(function ChatMessage(props) {
  const { id, text, total_tokens, ai = false, error = false } = props.message;
  const [localFeedback, setLocalFeedback] = useState(props.message.feedback || 0);
  const params = useParams();
  const textToCopyRef = useRef(null);

  const handleCopyToClipboard = () => {
    const textToCopy = textToCopyRef.current.innerText;
    navigator.clipboard.writeText(textToCopy);
  };

  const handleEnterSavePrompt = (e) => {
    e.preventDefault();
    const textToCopy = textToCopyRef.current.innerText;
    props.setSaveChatMessage(textToCopy);
    props.bookmarkPressedFunc(true);
  };

  const sendFeedback = async (id, newFeedback) => {
    try {
      await SessionHistoryAPI.setResponseFeedback(params.sessionId, id, newFeedback);
      setLocalFeedback(newFeedback);
    } catch (error) {
      console.error('Error setting feedback: ', error);
    }
  };

  const commonProps = {
    id,
    text,
    total_tokens,
    ai,
    error,
    feedback: localFeedback,
    sendFeedback,
    handleCopyToClipboard,
    handleEnterSavePrompt,
    textToCopyRef,
    ...props
  };

  return isMobile ? (
    <ChatMessageMobile {...commonProps} />
  ) : (
    <ChatMessageDesktop {...commonProps} />
  );
}, (prevProps, nextProps) => {
  return prevProps.message.id === nextProps.message.id;
});

export default ChatMessage;