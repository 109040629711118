import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from '../Modal';
import DatePicker from "react-datepicker";
import { MdCalendarMonth, MdOutlineSettings } from "react-icons/md";
import "react-datepicker/dist/react-datepicker.css";
import formats from '../../utils/formatting';

const reportTypes = [
  {
    id: 'root_cause_insights',
    name: 'Root Cause Insights & Preventative Actions',
    description: 'Delve into the primary issues causing customers to call, along with suggested preventative measures.',
    subTypes: [
      {
        id: 'comprehensive_overview',
        name: 'Comprehensive Overview',
        description: 'Gain a holistic understanding of the overarching root causes and preventative actions.',
        queryKey: 'comprehensive_overview',
        apiParams: {
          query_name: 'comprehensive_overview',
          summarize_result: false,
          // "model": Optional[str] = 'anthropic.claude-3-sonnet-20240229-v1:0'
        },
        display: {
          options: {
            columns: [
              "Root Cause", { name: "Frequency", align: "right" }, "Preventative Actions"
            ],
          },
          transform: (data) => {
            return data.map((item) => {
              return {
                rootCause: item._id,
                frequency: item.count,
                preventativeActions: "<ul>" + item.top_preventative_actions.map((pa) => "<li>" + pa._id + "</li>").join('') + "</ul>"
              }
            });
          }
        }
      },
      {
        id: 'agent_breakdown',
        name: 'Agent Breakdown',
        description: 'Explore calls handled, root causes, sentiment and call duration by agent.',
        queryKey: 'agent_breakdown',
        apiParams: {
          query_name: 'agent_breakdown',
          summarize_result: false,
          // "model": Optional[str] = 'anthropic.claude-3-sonnet-20240229-v1:0'
        },
        display: {
          options: {
            columns: [
              "Agent ID", { name: "Calls Handled", align: "right" }, { name: "Average Call Time <br/>(mins)", align: "right" }, "Most Common Root Causes <br/>(Frequency)", "Most Common Sentiment <br/>(Frequency)"
            ],
          },
          transform: (data) => {
            /** 
             * this result set comes back in 4 parts per agent id;
             * 1 row for total call count per agent
             * 1 row for the average call duration per agent
             * 1 row for the top sentiments (up to 3) per agent
             * 1 row for the top root causes (up to 5) per agent
             * This transform function will combine them into a single row for each day 
          */
            const merged = data.reduce((acc, item) => {
              let idx = acc.findIndex((a) => a._id === item._id);
              if (idx >= 0) {
                if (item.topRootCauses) {
                  acc[idx].topRootCauses = "<table>" + item.topRootCauses.map((rc) => {
                    return "<tr><td>" + rc.rootCause + "</td><td>" + rc.count + "</td></tr>";
                  }).join("") + "</table>";

                } else if (item.topSentiments) {
                  acc[idx].topSentiments = "<table>" + item.topSentiments.map((st) => {
                    return "<tr><td>" + st.sentiment + "</td><td>" + st.count + "</td></tr>";
                  }).join("") + "</table>";
                } else {
                  acc[idx] = {
                    ...acc[idx],
                    ...item
                  }
                }

              } else {
                acc.push(item);
              }
              return acc;
            }, []);

            const formatted = merged.map((item) => {
              const segments = item._id.split('@');
              segments.splice(2, 1);
              const agentId = segments.join('@');
              item._id = agentId
              item.averageCallDuration = formats.millisToMinutesAndSeconds(item.averageCallDuration);
              return item;
            });

            const sorted = formatted.sort((a, b) => b.totalCalls - a.totalCalls);

            return sorted;
          }
        }
      },
      {
        id: 'daily_breakdown',
        name: 'Daily Breakdown',
        description: 'Discover how issues fluctuate day by day.',
        queryKey: 'daily_breakdown',
        apiParams: {
          query_name: 'daily_breakdown',
          summarize_result: false,
          // "model": Optional[str] = 'anthropic.claude-3-sonnet-20240229-v1:0'
        },
        presetParams: {
          date_unit: 'day'
        },
        display: {
          options: {
            columns: [
              "Date", { name: "Total Calls", align: "right" }, { name: "Average Call Time", align: "right" }, "Top Root Cause", { name: "Count", align: "right" }, "Top Sentiment", { name: "Count", align: "right" }
            ],
          },
          transform: (data) => {
            /** 
             * this result set comes back in 3 parts per day;
             * 1 row for total call count per day
             * 1 row for the top sentiment per day
             * 1 row for the top root cause per day
             * This transform function will combine them into a single row for each day 
          */
            const merged = data.reduce((acc, item) => {
              let idx = acc.findIndex((a) => a._id === item._id);
              if (idx >= 0) {
                acc[idx] = {
                  ...acc[idx],
                  ...item
                }
              } else {
                acc.push(item);
              }
              return acc;
            }, []);

            const formatted = merged.map((item) => {
              item.averageCallTime = formats.millisToMinutesAndSeconds(item.averageCallTime);
              return item;
            });

            const sorted = formatted.sort((a, b) => new Date(a._id) - new Date(b._id));

            return sorted;
          }
        }
      },
      {
        id: 'monthly_breakdown',
        name: 'Monthly Breakdown',
        description: 'Discover how issues fluctuate month by month.',
        queryKey: 'daily_breakdown',
        apiParams: {
          query_name: 'daily_breakdown',
          summarize_result: false,
          // "model": Optional[str] = 'anthropic.claude-3-sonnet-20240229-v1:0'
        },
        presetParams: {
          date_unit: 'month'
        },
        display: {
          options: {
            columns: [
              "Date", { name: "Total Calls", align: "right" }, { name: "Average Call Time", align: "right" }, "Top Root Cause", { name: "Count", align: "right" }, "Top Sentiment", { name: "Count", align: "right" }
            ],
          },
          transform: (data) => {
            /** 
             * this result set comes back in 3 parts per day;
             * 1 row for total call count per day
             * 1 row for the top sentiment per day
             * 1 row for the top root cause per day
             * This transform function will combine them into a single row for each day 
          */
            const merged = data.reduce((acc, item) => {
              let idx = acc.findIndex((a) => a._id === item._id);
              if (idx >= 0) {
                acc[idx] = {
                  ...acc[idx],
                  ...item
                }
              } else {
                acc.push(item);
              }
              return acc;
            }, []);

            const formatted = merged.map((item) => {
              item.averageCallTime = formats.millisToMinutesAndSeconds(item.averageCallTime);
              return item;
            });

            const sorted = formatted.sort((a, b) => new Date(a._id) - new Date(b._id));

            return sorted;
          }
        }
      },
      {
        id: 'duration_breakdown',
        name: 'Call Duration Analysis',
        description: 'Explore the relationship between call length and root causes.',
        queryKey: 'duration_breakdown',
        apiParams: {
          query_name: 'duration_breakdown',
          summarize_result: false,
          // "model": Optional[str] = 'anthropic.claude-3-sonnet-20240229-v1:0'
        },
        display: {
          options: {
            columns: [
              "Call Length Category", "Calls Handled", "Top 5 Root Causes / Frequency", "Most Common Sentiment / Frequency"
            ],
          },
          transform: (data) => {
            /**
             * this result set comes back in 3 parts;
             * 3 rows for total counts for each short / medium / long calls
             * 3 rows for the top sentiment for each short / medium / long calls
             * 3 rows for the top root causes (up to 5) for each short / medium / long calls
             * This transform function will combine them into a single row for each call length 

             "result": [
              {
                  "_id": "Long",
                  "totalCalls": 7
              },
              {
                  "_id": "Medium",
                  "totalCalls": 20
              },
              {
                  "_id": "Short",
                  "totalCalls": 23
              },
              {
                  "_id": "Long",
                  "topSentiment": "MIXED",
                  "topSentimentCount": 4
              },
              {
                  "_id": "Medium",
                  "topSentiment": "MIXED",
                  "topSentimentCount": 10
              },
              {
                  "_id": "Short",
                  "topSentiment": "NEUTRAL",
                  "topSentimentCount": 9
              },
              
              {
                "callLengthCategory": "Long",
                "topRootCauses": [
                  {
                    "rootCause": "Promotions Issue",
                    "count": 27
                  },
                  {
                    "rootCause": "Reset Password",
                    "count": 14
                  },
                  {
                    "rootCause": "Return/Refund Status",
                    "count": 13
                  },
                  {
                    "rootCause": "Never Received",
                    "count": 13
                  },
                  {
                    "rootCause": "Defective/Damaged Return",
                    "count": 11
                  }
                ],
              },

              { ...medium },
              { ...short }

            ],
            */
            const merged = data.reduce((acc, item) => {
              let idx = acc.findIndex((a) => a._id === item._id);
              if (idx >= 0) {

                if (item.topRootCauses) {
                  acc[idx].topRootCauses = "<table>" + item.topRootCauses.map((rc) => {
                    return "<tr><td>" + rc.rootCause + "</td><td>" + rc.count + "</td></tr>";
                  }).join("") + "</table>";

                } else if (item.topSentiment) {
                  acc[idx].topSentiment = "<table><tr><td>" + item.topSentiment + "</td><td>" + item.topSentimentCount + "</td></tr><tr><td> </td><td> </td></tr></table>";

                } else {
                  acc[idx] = {
                    ...acc[idx],
                    ...item
                  }
                }
              } else {
                acc.push(item);
              }
              return acc;
            }, []);

            const formatted = merged.map((item) => {
              item._id += '<br/>' + (item._id === 'Short' ? '< 5 mins' : item._id === 'Medium' ? '5-7 mins' : '> 7 mins');
              return item;
            });

            return formatted;
          }
        }
      },
      {
        id: 'sentiment_breakdown',
        name: 'Sentiment Analysis',
        description: 'Explore the relationship call sentiment and root causes.',
        queryKey: 'sentiment_breakdown',
        apiParams: {
          query_name: 'sentiment_breakdown',
          summarize_result: false,
          // "model": Optional[str] = 'anthropic.claude-3-sonnet-20240229-v1:0'
        },
        display: {
          options: {
            columns: [
              "Sentiment", "Top 5 Root Causes / Frequency", { name: "Average Call Time", align: "right" }
            ],
          },
          transform: (data) => {
            /** this is how the result comes back;

            "result": [
              {
                "_id": "POSITIVE",
                "topRootCauses": [
                  {
                    "rootCause": "Help Returning Order",
                    "count": 21
                  },
                  {
                    "rootCause": "Promotions Issue",
                    "count": 15
                  },
                  {
                    "rootCause": "Order Status",
                    "count": 6
                  },
                  {
                    "rootCause": "Account Info Change",
                    "count": 6
                  },
                  {
                    "rootCause": "Exchange Request",
                    "count": 5
                  }
                ],
                "averageCallDuration": 167275.40404040404
            },
              {
                  "_id": "MIXED",
                  "topRootCauses": [ ... ],
                  "averageCallDuration": 173325.0
              },
              ... NEUTRAL, NEGATIVE

            ],
            */
            const mapped = data.map((item) => {
              item.topRootCauses = "<table>" + item.topRootCauses.map((rc) => {
                return "<tr><td>" + rc.rootCause + "</td><td>" + rc.count + "</td></tr>";
              }).join("") + "</table>";
              item.averageCallDuration = formats.millisToMinutesAndSeconds(item.averageCallDuration);
              return item;
            });

            const sortOrder = ['POSITIVE', 'MIXED', 'NEUTRAL', 'NEGATIVE'];

            const sorted = mapped.sort((a, b) => {
              return sortOrder.indexOf(a._id) - sortOrder.indexOf(b._id);
            });

            return sorted;
          }
        }
      }
    ]
  },
  {
    id: 'root_cause_distribution',
    name: 'Root Cause Metrics',
    description: 'Explore the frequency and distribution of root causes within your call interactions.',
    queryKey: 'root_cause_distribution',
    apiParams: {
      query_name: 'root_cause_distribution',
      summarize_result: false,
      // "model": Optional[str] = 'anthropic.claude-3-sonnet-20240229-v1:0'
    },
    display: {
      options: {
        columns: ["Root Cause", { name: "Frequency", align: "right" }, { name: "Percentage", align: "right" }],
      }
    }
  },
  {
    id: 'call_summaries',
    name: 'Call Summaries',
    description: 'Generate summaries of individual call interactions with the selected scope.',
    queryKey: 'call_summaries',
    apiParams: {
      query_name: 'call_summaries',
      summarize_result: false,
      // "model": Optional[str] = 'anthropic.claude-3-sonnet-20240229-v1:0'
    },
    display: {
      options: {
        columns: [
          "Call Id", "Call Length", "Day", "Time", "Overall Sentiment", "Summary", "Preventative Action", "Root Cause Reason"
        ],
      },
      transform: (data) => {
        /**
          {
            "_id": "0134006e-a89b-461a-bdfd-8514b3dd8e53",
            "call_time": "2024-01-02T17:35:16",
            "duration": 118405,
            "root_cause": [
              {
                "category": "Returns",
                "reason": "Exchange Request"
              }
            ],
            "preventative_action": "Offer exchange option upfront, provide clear return policy",
            "call_summary": "Customer needed to exchange an item but was told they could only return it for a refund. Agent was able to provide a return label and explained the customer could then place a new order at the original discounted price.",
            "overall_call_sentiment": "MIXED"
          }
         */
        return data.map((item) => {
          const m = {
            callId: item._id,
            duration: formats.millisToMinutesAndSeconds(item.duration),
            day: new Date(item.call_time).toLocaleDateString(),
            time: new Date(item.call_time).toLocaleTimeString(),
            sentiment: item.overall_call_sentiment,
            summary: item.call_summary,
            preventative: item.preventative_action,
            rootCause: (item.root_cause || [])[0]?.reason, // item.root_cause[0].category + ': ' + item.root_cause[0].reason,
          }
          return m
        });
      }
    }
  },
  {
    id: 'alternative_questions',
    name: 'Alternative Questions',
    description: 'Continue to chat window to ask a question about the transcripts selected.',
    queryKey: 'ad_hoc_query',
    isAdHoc: true,
  },
];

/**
 * A component that displays an image.
 *
 * @param {string} text - The source of the image to display.
 * @returns {JSX.Element} - A JSX element representing the image.
 */
const RootCauseReportSelection = (props) => {

  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [selectedReportType, setSelectedReportType] = useState(null);
  const [selectedReportSubType, setSelectedReportSubType] = useState(null);
  const [formValid, setFormValid] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const params = useParams();

  const toUtcDateBegin = (date) => {
    const YYYY = date.getFullYear();
    const MM = date.getMonth();
    const DD = date.getDate();
    return new Date(Date.UTC(YYYY, MM, DD, 0, 0, 0, 0));
  };

  const toUtcDateEnd = (date) => {
    const YYYY = date.getFullYear();
    const MM = date.getMonth();
    const DD = date.getDate();
    return new Date(Date.UTC(YYYY, MM, DD, 23, 59, 59, 999));
  };

  const submitQuery = () => {
    let report = selectedReportSubType || selectedReportType;

    // attach selected parameters
    if(report.apiParams) {
      report.apiParams.params = {
        start_date: toUtcDateBegin(dateFrom),
        end_date: toUtcDateEnd(dateTo),
        // include any preset parameters specified for the report
        ...report.presetParams 
      }
    }

    setModalOpen(false);

    const query = report.isAdHoc ? "" : `${report.name}, from ${new Date(dateFrom).toLocaleDateString()} to ${new Date(dateTo).toLocaleDateString()}`;
    props.submitReportQuery(report.apiParams, query, !!report.isAdHoc, report.display);

  };

  const cancelQuery = () => {
    setModalOpen(false);
    if (!params.sessionId) {
      navigate('/text/usecases');
    }
  };

  useEffect(() => {
    setFormValid(dateFrom && dateTo && (dateFrom <= dateTo) && (selectedReportType?.queryKey || selectedReportSubType?.queryKey));
    console.log('form valid', !!formValid, dateFrom, dateTo, selectedReportType?.id, selectedReportSubType?.id);
  }, [selectedReportType, selectedReportSubType, dateFrom, dateTo, formValid, setFormValid]);

  return (
    <div>
      <Modal title="" modalOpen={modalOpen} useClose={false} classes={'use-case-params-modal'}>
        <div className="flex items-center justify-center bg-gray-100">
          <div className="bg-white rounded-xl container mx-auto">
            <div className="text-center text-3xl font-bold text-gray-700 mb-4">
              Root Cause Analysis Generator
            </div>
            <p className="text-gray-400 text-lg">
              <span className="font-bold text-xl text-gray-600">Purpose:</span> Use Skechers to analyze customer support call transcripts for root causes and preventative actions.
            </p>
            <p className="text-gray-400 text-lg mt-4">
              <span className="font-bold text-xl text-gray-600">Instructions:</span> Initiate your root cause analysis by selecting your desired time period along with the specific type of analysis you want to undertake. Click "Go" to begin.
            </p>

            <div className="border-t-2 border-gray-300 mt-4 mb-4"></div>

            <p className="text-gray-400 text-lg mt-4">
            <span className="font-bold text-xl text-gray-600">Scope of Analysis</span>
            </p>
            <p className="text-gray-400 text-lg mt-4">
            <span className="text-gray-600">Date Range</span>
            </p>
            <p className="flex flex-row text-gray-400 text-lg mt-1">
              <div className="flex flex-row mr-16"><DatePicker selected={dateFrom} onChange={date => setDateFrom(date)} className="outline outline-1 rounded py-1 px-2"/><MdCalendarMonth style={{ marginLeft: "-34px", marginTop: "4px", zIndex: 2, pointerEvents: "none"}} size="1.5em" /></div>
              <div className="flex flex-row"><DatePicker selected={dateTo} onChange={date => setDateTo(date)} className="outline outline-1 rounded py-1 px-2"/><MdCalendarMonth style={{ marginLeft: "-34px", marginTop: "4px", zIndex: 2, pointerEvents: "none"}} size="1.5em" /></div>
            </p>
            <p className="text-gray-600 text-lg font-bold mt-4">
              Analysis Type
            </p>
            <div className="flex justify-start pl-6 items-end space-x-6 flex-wrap">
              {reportTypes.map((reportType) => (
                <div key={reportType.id} className={`mt-3 inline-block hover:bg-blue-400 text-slate-800 rounded-md1 h-40 w-52 p-4 ${reportType.id === selectedReportType?.id ? "bg-blue-400" : "bg-gray-300"}`} onClick={() => {setSelectedReportType(reportType); setSelectedReportSubType(null);}}>
                  <div className="font-bold text-center">
                    {reportType.name}
                  </div>
                  <div className="mt-3 text-xs text-center">
                    {reportType.description}
                  </div>
                </div>
              ))}
            </div>
            <div className={selectedReportType?.subTypes ? "visible": "invisible"}>
              <p className="text-gray-600 text-lg font-bold mt-4">
                Analysis Sub-Type
              </p>
              <div className="flex justify-start pl-6 items-end space-x-2 flex-wrap">
                {selectedReportType?.subTypes?.map((reportType) => (
                  <div key={reportType.id} className={`mt-3 inline-block hover:bg-blue-400 text-slate-800 rounded-md1 h-40 w-36 p-1 ${reportType.id === selectedReportSubType?.id ? "bg-blue-400" : "bg-gray-300"}`} onClick={() => setSelectedReportSubType(reportType)}>
                    <div className="font-bold text-center">
                      {reportType.name}
                    </div>
                    <div className="mt-3 text-xs text-center">
                      {reportType.description}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex justify-end items-end space-x-6">
              <button type="button" className="mt-4 inline-block bg-light-blue hover:bg-dark-blue text-white font-bold py-3 px-8 rounded-md" onClick={cancelQuery}>Cancel</button>
              <button type="button"disabled={!formValid} className="btn mt-4 inline-block bg-light-blue hover:bg-dark-blue text-white font-bold py-3 px-8 rounded-md" onClick={submitQuery}>Go</button>
            </div>
          </div>
        </div>
      </Modal>
      <button type="button" style={{ right: "8px", bottom: "148px" }} className="btn absolute inline-block bg-light-blue hover:bg-dark-blue text-white font-bold py-3 px-6 rounded-xl" onClick={() => setModalOpen(true)}><MdOutlineSettings size="1.5em" /></button>
    </div>
  )
};

export default RootCauseReportSelection;
export { reportTypes };

