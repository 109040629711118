import React from 'react';
import ConfigContainer from '../ConfigContainer';
import ImageConfig from '../ModelConfig/Image';
import SideBarDesktop from '../Common/Desktop/SideBarDesktop';
import ChatView from '../ChatView';
import Disclaimer from '../Disclaimer';
import { useState } from 'react';
import skechAILogo from '../../../src/assets/Light_BG_SkechAI_logo_sm.png'
import settings from '../../../src/assets/settings.png'


const ImageChatDesktop = () => {
  const [isConfigOpen, setIsConfigOpen] = useState(false);
  const uiState = {
    isConfigOpen: isConfigOpen,
    setIsConfigOpen: setIsConfigOpen,
  }

  return (
      <div className='flex transition duration-500 ease-in-out'>
          <SideBarDesktop modelType="image"/>
          <ChatView modelType="image"/>
          <ConfigContainer uiState={uiState}>
            <ImageConfig/>
          </ConfigContainer>
          <Disclaimer/>
      </div>
  );
}

export default ImageChatDesktop;
