import React from "react"
import { Settings } from "react-ionicons"
import skechAILogo from '../../../assets/Light_BG_SkechAI_logo.png'

const ConfigSettingsMobile = (props) => {
    return (
        <>
            <div className='config-settings-padding'>
                <div className="config-settings">
                    <img src={skechAILogo} className="w-18 h-10 pl-8" alt="Logo" />
                    <Settings
                        onClick={() => props.uiState.setIsConfigOpen(!props.uiState.isConfigOpen)}
                        color={'#0D47A1'}
                        height="30px"
                        width="30px"
                    />
                </div>
            </div>
            <div className="config-divider"></div>
        </>
    );
}

export default ConfigSettingsMobile
