import { createContext, useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { AuthAPI } from '../api/authAPI';
/**
 * ChatContext is a context object that is used to share collection of messages
 * between components
 */
const SessionContext = createContext({});

/**
 * ChatContextProvider is a functional component that serves as a provider for the ChatContext.
 * It provides the ChatContext to the components within its subtree.
 *
 * @param {Object} props - The properties passed to the component.
 * @returns {JSX.Element} A ChatContext.Provider element.
 */
const SessionContextProvider = (props) => {

  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  const [imgSessionId, setImgSessionId] = useState(null);
  const [txtSessionId, setTxtSessionId] = useState(null);
  const [imgSessionTitle, setImgSessionTitle] = useState("");
  const [txtSessionTitle, setTxtSessionTitle] = useState("");

  useEffect(() => {

    if(process.env.REACT_APP_AUTH_METHOD?.toLowerCase() !== 'okta') {
      return;
    }

    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      AuthAPI.udpateToken(authState.accessToken.accessToken);
      // console.log(authState.idToken.claims);
      // console.log(authState.accessToken);

      // get user information from `/userinfo` endpoint
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
        const promises = [];
        promises.push(AuthAPI.getUserRoles());
        promises.push(AuthAPI.getUserGroups());
        Promise.all(promises).then((results) => {
          const roles = results[0];
          const groups = results[1];
          info.skechaiRoles = roles.data;
          info.skechaiGroups = groups.data;
          setUserInfo(info);
          console.log("User Info", info);
        });
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  return (
    <SessionContext.Provider value={[imgSessionId, setImgSessionId, txtSessionId, setTxtSessionId, imgSessionTitle, setImgSessionTitle, txtSessionTitle, setTxtSessionTitle, userInfo]}>
      {props.children}
    </SessionContext.Provider>
  );
};

export { SessionContext, SessionContextProvider };
