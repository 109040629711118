import { api } from "./configs/axiosConfig";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const UsageType = {
  ALL: "all",
  CHAT: "chat",
  IMAGE: "image",
};

export const UsageAPI = {
  
  usage: async function (modelType, startDate, endDate, cancel = false) {

    const requestOptions = {};
    startDate && (requestOptions.start_date = startDate);
    endDate && (requestOptions.end_date = endDate);

    try {
      const response = await api.request({
        url: `/admin/usage/${modelType}`,
        method: "POST",
        data: requestOptions,
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  groups: async function(search = "", limitToPreset = false, cancel = false) {
    const requestOptions = {
      search: search,
      limit_to_preset: limitToPreset,
    };

    try {
      const response = await api.request({
        url: "/admin/groups",
        method: "POST",
        data: requestOptions,
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  groupUsage: async function(usageType, groupId, startDate, endDate, cancel = false) {
    const requestOptions = {};
    startDate && (requestOptions.start_date = startDate);
    endDate && (requestOptions.end_date = endDate);
    groupId && (requestOptions.group_id = groupId);

    try {
      const response = await api.request({
        url: `/admin/usage/group/${usageType}`,
        method: "POST",
        data: requestOptions,
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

};


// defining the cancel API object for UsageAPI
const cancelApiObject = defineCancelApiObject(UsageAPI)