import React, { useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SessionContext } from '../../context/sessionContext';
import ImageChatDesktop from './ImageChatDesktop';
import ImageChatMobile from './ImageChatMobile';
import { isMobile } from 'react-device-detect';

const queryClient = new QueryClient();

const ImageChat = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [imgSessionId] = useContext(SessionContext);

  // if we navigated here without a session ID, if there is a current one then set it on the URL and use it
  useEffect(() => {
    if (!params.sessionId && imgSessionId) {
      params.sessionId = imgSessionId;
      navigate(`/image/${imgSessionId}`, { replace: true });
    }
  }, [navigate, params, imgSessionId]);
  return (
    <QueryClientProvider client={queryClient}>
      {isMobile ? <ImageChatMobile /> : <ImageChatDesktop />}
    </QueryClientProvider>
  );
}

export default ImageChat;
