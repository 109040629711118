import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderMobile from '../Common/Mobile/HeaderMobile';
import SideBarMobile from '../Common/Mobile/SideBarMobile';
import { FaUndo } from "react-icons/fa";
import moment from 'moment';
import deleteIcon from '../../assets/delete_icon.png';
import MobileConfigContainer from '../MobileConfigContainer';
import TextConfig from '../ModelConfig/Text';
import ConfigSettingsMobile from '../Common/Mobile/ConfigSettingsMobile';

const ChatHistoryMobile = ({
  chatSessions,
  loadSession,
  removeSession,
  cancelRemoveSession,
  deletingSessionIds,
  modelType
}) => {
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isConfigOpen, setIsConfigOpen] = useState(false);

  const handleModelTypeChange = (newModelType) => {
    navigate(`/${newModelType}/history`);
    setDropdownOpen(false);
  };

  const uiState = {
    modelType: modelType,
    sideBarOpen: sideBarOpen,
    setSideBarOpen: setSideBarOpen,
    onModelTypeChange: handleModelTypeChange,
    dropdownOpen: dropdownOpen,
    setDropdownOpen: setDropdownOpen,
    isConfigOpen: isConfigOpen,
    setIsConfigOpen: setIsConfigOpen,
  }


  return (
    <div className='flex transition duration-500 ease-in-out'>
      <SideBarMobile modelType={modelType} uiState={uiState} />
      <div className="flex flex-col h-screen w-full bg-white">
        <HeaderMobile uiState={uiState} showSettings={false}/>
        <div className="flex-1 overflow-y-auto p-4">
          <div className="chat-history-header">
            <h1 className="chat-history-title">Chat History</h1>
          </div>
          {chatSessions.map((session) => (
            <div
              key={session.session_id}
              id={session.session_id}
              className={`chat-history-item chat-history-sub ${deletingSessionIds.includes(session.session_id) ? 'bg-red-100 opacity-50' : ''
                }`}
            >
              <div onClick={() => loadSession(session.session_id)} className="cursor-pointer flex-grow">
                <h2>{session.title}</h2>
                <p className="description">{session.description}</p>
              </div>
              <p className="date">
                {moment.utc(session.last_updated).local().format('MMMM Do YYYY, h:mm a')}
              </p>
              <div>
                {deletingSessionIds.includes(session.session_id) ? (
                  <button
                    onClick={() => cancelRemoveSession(session.session_id)}
                    className="text-blue-500 hover:text-blue-700"
                  >
                    <FaUndo />
                  </button>
                ) : (
                  <button
                    onClick={() => removeSession(session.session_id)}
                    className="text-blue-500 hover:text-blue-700"
                  >
                    <img src={deleteIcon} alt="Delete" className="w-5 h-5" />
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
        {isConfigOpen && (
          <MobileConfigContainer uiState={uiState}>
            <ConfigSettingsMobile uiState={uiState} />
            <TextConfig />
          </MobileConfigContainer>
        )}
      </div>
    </div>
  );
};

export default ChatHistoryMobile;