import React, { useState, useEffect } from 'react';
import Modal from '../Modal';
import { FaTimes } from "react-icons/fa";
import { FavoritesAPI } from '../../api/favoritesAPI';

/*
    Component takes Prompt Name and Prompt Content. Prompt Content auto populated after pressing bookmark, but adjustable. Then we can send 
    a prompt, which will auto populate the inout bar. Used MongoDB Axios calls in favoritesAPI to adjust API after save.

    INPUTS:
        promptContent: The initial content for the save modal to show (from the bookmarked chat)
        initialModalPos: The position of Modal (open or close) from parent
        updateFunc: the function to update a parent component value in child (in this case modal pos)

*/

const SavePromptDesktop = ({ savePromptModalProps }) => {
    const { promptContent, initialModalPos, updateFunc } = savePromptModalProps;
    const [promptName, setPromptName] = useState('');
    const [content, setContent] = useState(promptContent || '');
    const [prevPromptContent, setPrevPromptContent] = useState('');
    const [error, setError] = useState(false);


    const handleExit = (e) => {
        updateFunc(false);
        setPromptName('');
    }

    const handleSendPrompt = (e) => {
        if (promptName == "") {
            setError(true);
            return;
        }
        FavoritesAPI.addFavorite(promptName, content);
        setError(false);
        updateFunc(false);
        setPromptName('');

    }

    useEffect(() => {
        if (initialModalPos && promptContent !== prevPromptContent) {
            setPromptName('');
            setContent(promptContent);
            setPrevPromptContent(promptContent);

        }
    }, [promptContent]);

    return (
        <Modal title="" modalOpen={initialModalPos} useClose={false} classes='save-prompt-modal' useRelative={false}>
            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-1000 w-[35rem] h-[37.5rem] rounded-3xl flex flex-col justify-between">
                <div className="bg-dark-blue w-[50rem] h-[50rem] rounded-3xl flex flex-col justify-between">
                    <div className="flex items-center justify-between px-6 py-4">
                        <div className="flex items-center text-xl text-white">
                            <span>Save Prompt</span>
                        </div>
                        <button className="text-white" onClick={handleExit}> <FaTimes /> </button>
                    </div>

                    <div className="flex flex-col px-6 flex-grow">
                        <div className="flex flex-col mb-4">
                            <label htmlFor="promptName" className="text-white mb-2">
                                Prompt Name
                            </label>
                            <textarea
                                id="promptName"
                                className={`bg-black text-white rounded-lg p-2 resize-none box-border ${error && promptName.trim() === '' ? 'border-[0.5px] border-2 border-red-500 box-border' : 'border-[0.5px] border-transparent'
                                    }`}
                                value={promptName}
                                onChange={(e) => setPromptName(e.target.value)}
                            />
                        </div>

                        <div className="flex flex-col mb-4 flex-grow">
                            <label htmlFor="promptContent" className="text-white mb-2">
                                Prompt Content
                            </label>
                            <textarea
                                id="promptContent"
                                className="bg-black text-white rounded-lg p-2 resize-none flex-grow"
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                            />
                        </div>

                        <div className="flex justify-end pb-5">
                            <button className="bg-light-blue text-white rounded-lg px-5 py-2 capitalize" onClick={handleSendPrompt}>
                                Save Prompt
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SavePromptDesktop;
