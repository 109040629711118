import React, { useState, useCallback, useEffect } from 'react';
import { AuthAPI } from '../api/authAPI';
import { MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox } from "react-icons/md";

const Login = ({ onLogin }) => {
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [passwordNew, setPasswordNew] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [error, setError] = useState('');

  const [forcePasswordUpdate, setForcePasswordUpdate] = useState(false);
  const [validLength, setValidLength] = useState(false);
  const [validNumber, setValidNumber] = useState(false);
  const [validSpecialChar, setValidSpecialChar] = useState(false);
  const [validMatch, setValidMatch] = useState(false);
  const [valid, setValid] = useState(false);
  const [waiting, setWaiting] = useState(false);


  const validateNewPassword = useCallback((pwd) => {

    const requirements = {
      minLength: 8,
      requiresNumber: true,
      requiresSpecialChar: true
    }

    setValidLength(pwd.length >= requirements.minLength);
    setValidNumber(/\d/.test(pwd));
    setValidSpecialChar(/[!@#$%^&*]/.test(pwd));
    setValid(pwd.length >= requirements.minLength && /\d/.test(pwd) && /[!@#$%^&*]/.test(pwd));
    setValidMatch(valid && pwd === passwordConfirm);

  }, [valid, passwordConfirm]);

  const validatePasswordConfirm = useCallback((confirm) => {
    setValidMatch(valid && passwordNew === confirm);
  }, [passwordNew, valid]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    setWaiting(true);
    if (forcePasswordUpdate) {
      AuthAPI.updatePassword(userId, password, passwordNew).then(async (response) => {
        // receive JWT token, store it in local storage 
        // and set the authentication flag to true
        setWaiting(false);
        const data = response.data;
        if (data.access_token) {
          localStorage.setItem('auth_token', data.access_token);
          AuthAPI.udpateToken(data.access_token);
          onLogin(true);
        } else {
          setError(data.error || "Login failed");
        }
      }, (error) => {
        setWaiting(false);
        setError(`${error.response?.data?.detail?.error || "Login failed"}`);
      });
    } else {
      AuthAPI.login(userId, password).then(async (response) => {
        // receive JWT token, store it in local storage 
        // and set the authentication flag to true
        setWaiting(false);
        const data = response.data;
        if (data.access_token) {
          localStorage.setItem('auth_token', data.access_token);
          AuthAPI.udpateToken(data.access_token);
          onLogin(true);
        } else if (data.status === "Password Reset Required") { 
          setForcePasswordUpdate(true);
        } else {
          setError(data.error || "Login failed");
        }
      }, (error) => {
        setWaiting(false);
        setError(`${error.response?.data?.detail?.error || "Login failed"}`);
      });
    }
  }, [userId, password, passwordNew, onLogin, forcePasswordUpdate]);

  useEffect(() => {
    setWaiting(false);
  }, [userId, password]);

  return (
    <div className="h-screen flex justify-center items-center bg-slate-300">
      <form className="bg-white p-6 rounded shadow-lg" onSubmit={handleSubmit}>

          {forcePasswordUpdate ? (
            <div className="grid grid-cols-1 gap-y-4">
              <div className="">
                <p className="text-2xl bold">Password Update Required</p>
                Please create a new password that meets the following requirements:
                <ul className="m-4">
                  <li className="flex">
                    <MdOutlineCheckBoxOutlineBlank className={`text-2xl text-red-500 mx-2 ${validLength ? "hidden" : ""}`}/>
                    <MdOutlineCheckBox className={`text-2xl text-green-500 mx-2 ${validLength ? "" : "hidden"}`}/>
                    Minimum 8 characters
                  </li>
                  <li className="flex">
                  <MdOutlineCheckBoxOutlineBlank className={`text-2xl text-red-500 mx-2 ${validNumber ? "hidden" : ""}`}/>
                    <MdOutlineCheckBox className={`text-2xl text-green-500 mx-2 ${validNumber ? "" : "hidden"}`}/>
                    At least one number
                  </li>
                  <li className="flex">
                  <MdOutlineCheckBoxOutlineBlank className={`text-2xl text-red-500 mx-2 ${validSpecialChar ? "hidden" : ""}`}/>
                    <MdOutlineCheckBox className={`text-2xl text-green-500 mx-2 ${validSpecialChar ? "" : "hidden"}`}/>
                    At least one special character
                  </li>
                  <li className="flex">
                  <MdOutlineCheckBoxOutlineBlank className={`text-2xl text-red-500 mx-2 ${validMatch ? "hidden" : ""}`}/>
                    <MdOutlineCheckBox className={`text-2xl text-green-500 mx-2 ${validMatch ? "" : "hidden"}`}/>
                    New Password & Confirm New Password match
                  </li>
                </ul>
              </div>
              <div className="grid grid-cols-3 items-center">
                <label htmlFor="password" className="text-right mr-4">Current Password:</label>
                <input
                    type="password"
                    className="col-span-2 border-2 p-2 rounded w-full"
                    value={password}
                    onChange={(e) => {setPassword(e.target.value)}}
                />
              </div>
              <div className="grid grid-cols-3 items-center">
                <label htmlFor="password" className="text-right mr-4">New Password:</label>
                <input
                    type="password"
                    className="col-span-2 border-2 p-2 rounded w-full"
                    value={passwordNew}
                    onChange={(e) => {setPasswordNew(e.target.value); validateNewPassword(e.target.value);}}
                />
              </div>
              <div className="grid grid-cols-3 items-center">
                <label htmlFor="password-confirm" className="text-right mr-4">Confirm New Password:</label>
                <input
                    type="password"
                    className="col-span-2 border-2 p-2 rounded w-full"
                    value={passwordConfirm}
                    onChange={(e) => {setPasswordConfirm(e.target.value); validatePasswordConfirm(e.target.value);}}
                />
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-1 gap-y-4">
              <div className="grid grid-cols-3 items-center">
                  <label htmlFor="nameName" className="text-right mr-4">Name:</label>
                  <input
                      type="text"
                      className="col-span-2 border-2 p-2 rounded w-full"
                      value={userId}
                      onChange={(e) => {setUserId(e.target.value); setError('');}}
                  />
              </div>

              <div className="grid grid-cols-3 items-center">
                  <label htmlFor="password" className="text-right mr-4">Password:</label>
                  <input
                      type="password"
                      className="col-span-2 border-2 p-2 rounded w-full"
                      value={password}
                      onChange={(e) => {setPassword(e.target.value); setError('');}}
                  />
              </div>
            </div>
          )}

            <div className="flex justify-center mt-6">
                <button disabled={waiting || !password || (forcePasswordUpdate && (!valid || !validMatch)) } type="submit" className="bg-blue-500 text-white px-4 py-2 rounded enabled:hover:bg-blue-700 disabled:opacity-75">Submit</button>
            </div>
            {error && <p className="text-red-600 text-center">{error}</p>}

        </form>
    </div>
  );
};

export default Login;
