import React from 'react';
import { FaRegBookmark, FaStop, FaArrowRight, FaTimes } from 'react-icons/fa';
import { MdOutlineAttachFile } from 'react-icons/md';
import { Tooltip } from 'react-tooltip';

const ChatFormMobile = ({
  chatProps,
}) => {
  return (
    <form className="form chat flex-col" onSubmit={chatProps.sendMessage}>
      <div className="flex flex-col w-full border rounded-2xl shadow-sm">
        {chatProps.selectedFileInfo && (
          <div className="flex items-center justify-between px-3 py-2 bg-blue-50 rounded-t-2xl">
            <span className="text-sm text-blue-600 truncate">
              {chatProps.selectedFileInfo.file_name}
            </span>
            <button
              type="button"
              onClick={() => chatProps.clearSelectedFile()}
              className="text-blue-600 hover:text-blue-800"
            >
              <FaTimes className="w-4 h-4" />
            </button>
          </div>
        )}
        <textarea
          className={`input w-full outline-none focus:outline-none ${
            chatProps.selectedFileInfo ? 'rounded-none' : 'rounded-t-2xl'
          } disabled:bg-white max-h-40 min-h-12 p-2.5 resize-none`}
          value={chatProps.formValue}
          ref={chatProps.inputRef}
          disabled={chatProps.waiting}
          onKeyDown={chatProps.handleKeyDown}
          onChange={(e) => chatProps.setFormValue(e.target.value)}
          placeholder="Ask Anything..."
        />
        <div className="flex justify-between items-center px-3 py-2 border-t">
          <div className="flex space-x-4">
            <button
              type="button"
              onClick={chatProps.handleEnterDropdown}
              className="flex items-center space-x-2 text-blue-600 hover:bg-blue-50 px-2 py-2 rounded-md transition-colors"
            >
              <FaRegBookmark className="w-4 h-4" />
              <span className="text-sm font-medium">Saved Prompts</span>
            </button>
            {(chatProps.modelType === "text" || chatProps.modelType === "image") && (
              <>
                <input type="file" ref={chatProps.fileInputRef} onChange={chatProps.handleFileSelect} accept={chatProps.fileTypes[chatProps.modelType]} className="hidden" />
                <button
                  className={`flex items-center space-x-2 text-blue-600 hover:bg-blue-50 px-2 py-2 rounded-md transition-colors ${chatProps.selectedFileInfo ? 'bg-blue-50' : ''}`}
                  type="button"
                  data-tooltip-id="upload-tooltip"
                  onClick={chatProps.selectFile}
                  disabled={chatProps.uploading}
                >
                  <MdOutlineAttachFile className="w-4 h-4" />
                  <span className="text-sm font-medium">Attach</span>
                </button>
              </>
            )}
          </div>
          <div className="flex items-center space-x-4">
            {chatProps.waiting ? (
              <button
                className="flex items-center justify-center w-10 h-10 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
                type="button"
                onClick={chatProps.cancelResponse}
              >
                <FaStop className="w-4 h-4" />
              </button>
            ) : (
              <button
                className="flex items-center justify-center w-10 h-10 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
                type="submit"
                disabled={(!chatProps.formValue && !chatProps.selectedFileInfo?.file_name) || chatProps.waiting}
              >
                <FaArrowRight style={{ transform: 'scaleX(1.2)' }} />
              </button>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default ChatFormMobile;
