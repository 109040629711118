export const DefaultImageConfig = {
    negativePrompts: [],
    height: 1024,
    width: 1024,
    cfgScale: 7,
    clipGuidancePreset: null,
    sampler: null,
    samples: 1,
    seed: 0,
    steps: 30,
    stylePreset: "",
    aspectRatio: "1:1",
    imageStrength: 0.35,
}