import { api } from "./configs/axiosConfig";
import { defineCancelApiObject } from "./configs/axiosUtils";

/* 
  API calls for Favorite Prompts

  getFavorites: takes in no inputs and gets all unique favorites.

  delFavorites: takes promptID and deletes associated favorite prompt

  addFavorites: takes Name, Prompt, optionally Type and adds that into the database
*/

export const FavoritesAPI = {
  getFavorites: async function (cancel = false) {
    try {
      const response = await api.request({
        url: `/user/favorite_prompt`,
        method: "GET",
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  delFavorite: async function (promptID, cancel = false) {
    try {
      const response = await api.request({
        url: `/user/favorite_prompt`,
        method: "DELETE",
        data: {
          prompt_id: promptID
        },
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  addFavorite: async function (Name, Prompt, Type = 'text', cancel = false) {
    try {
      const response = await api.request({
        url: `/user/favorite_prompt`,
        method: "POST",
        data: {
          name: Name,
          prompt: Prompt,
          type: Type,
        },
        signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
      });

      return response;
    } catch (error) {
      throw error;
    }
  },
};

const cancelApiObject = defineCancelApiObject(FavoritesAPI);