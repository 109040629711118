import React from 'react';
import SideBarDesktop from '../Common/Desktop/SideBarDesktop';
import help_1 from '../../assets/use_case_1.png';
import help_2 from '../../assets/use_case_2.png';
import help_3 from '../../assets/use_case_3.png';

const referenceGuide = [
  {
    modelType: 'text',
    title: 'Document Summarization',
    description: 'Generate concise summaries of documents.',
    imageName: help_3,
  },
  {
    modelType: 'text',
    title: 'Root Cause Analysis',
    description: 'Identify the underlying causes of issues.',
    imageName: help_1,
  },
  {
    modelType: 'text',
    title: 'Product Description Generation',
    description: 'Automatically create product descriptions.',
    imageName: help_2,
  },
  {
    modelType: 'image',
    title: 'Idea Generation',
    description: 'Unlock creativity with AI-driven title suggestions for your ideas.',
    imageName: help_3,
  },

];

const Help = (props) => {
  const filteredReferenceGuide = referenceGuide.filter((referenceGuide) => referenceGuide.modelType === props.modelType);
  return (
    <div className='flex transition duration-500 ease-in-out'>
      <SideBarDesktop modelType={props.modelType} />
      <div className="w-full">
        <div className="Help__header sticky top-0 bg-white shadow-md shadow-neutral-100 text-center text-2xl font-bold py-4">
          Help
        </div>
        <div className="ml-16 mr-20 mt-12 ">
          <h1 className="text-2xl font-bold mb-2">Welcome to Skechers' GPT Resource Center </h1>
          <p className="text-xl leading-9">This page contains Quick Reference Guides on use cases that have been built out through the Skechers' Generative SkechAI. Click on any of the use cases below to download step by step instructions.</p>
        </div>
        {/* <div className="ml-16 mr-20 mt-12">
          <h2 className="text-xl">If you have any additional questions, please contact:______________.</h2>
        </div> */}
        <div className="flex ml-16 mr-20 mt-6">
          {filteredReferenceGuide.map((referenceGuide, index) => (
            <div key={index} className="Help flex flex-col m-3">
              <div className="w-full h-1/2 rounded-b-3xl flex shrink-0">
                <img src={referenceGuide.imageName} alt='' className="w-full h-half" />
              </div>
              <div className="w-full h-1/2 bg-sky-100  relative overflow-hidden rounded-b-3xl flex shrink-0">
                <p className="ml-6 mt-3 text-base font-bold text-left text-blue-900">{referenceGuide.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Help;
