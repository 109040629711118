import axios from 'axios';

let bearerToken = null;

// get API URL from .env file using dotenv package
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'NOT CONFIGURED';
export const API_ENDPOINT_STREAM = process.env.REACT_APP_API_ENDPOINT_STREAM || 'NOT CONFIGURED';

export const api = axios.create({
    baseURL: API_ENDPOINT,
    timeout: 180000,
    headers: {
        // 'Content-Type': 'application/json',
        // accept: 'application/json',
        responseType: 'stream',
    },
});

export const setToken = (token) => {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    bearerToken = token;
}

export const getToken = () => {
    return bearerToken;
}

// defining a custom error handler for all APIs
const errorHandler = (error) => {
    const statusCode = error.response?.status

    // logging only errors that are not 401
    if (statusCode && statusCode !== 401) {
        console.error(error)
    }

    return Promise.reject(error)
}

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error) => {
    return errorHandler(error)
});


