import React from 'react';
import { FaStop, FaPlus } from 'react-icons/fa';
import { MdOutlineAttachFile, MdClose } from 'react-icons/md';
import { Tooltip } from 'react-tooltip';
import Airplane from '../../../assets/airplane.png';

const ChatFormDesktop = ({
    chatProps,
}) => {
    return (
        <form className="form chat flex-col" onSubmit={chatProps.sendMessage}>
            {(chatProps.modelType === "text" || chatProps.modelType === "image") && (<>
                <div className={`text-gray-500 ml-5 ${chatProps.uploading ? "" : "hidden"}`}>Uploading...</div>
                <div className="file-upload flex items-stretch w-full join justify-end my-2">
                    <input type="file" ref={chatProps.fileInputRef} onChange={chatProps.handleFileSelect} accept={chatProps.fileTypes[chatProps.modelType]} className="hidden" />
                    <input className={"text-lg text-gray-500 input grow input-bordered join-item rounded-s-md rounded-r-none text-right " + (chatProps.selectedFileInfo ? "" : "hidden")}
                        value={chatProps.selectedFileInfo?.file_name || ""}
                        readOnly={true}
                        placeholder="Upload a file..."
                    />
                    <button className={"btn join-item bg-light-blue rounded-xl hover:bg-dark-blue text-2xl " + (chatProps.uploading || chatProps.selectedFileInfo ? "hidden" : "")}
                        type="button"
                        data-tooltip-id="upload-tooltip"
                        onClick={chatProps.selectFile}>
                        <MdOutlineAttachFile />
                    </button>
                    <button className={"btn join-item bg-light-blue rounded-l-none rounded-r-xl hover:bg-dark-blue text-2xl " + (chatProps.selectedFileInfo ? "" : "hidden")}
                        type="button"
                        disabled={chatProps.uploading}
                        onClick={chatProps.clearSelectedFile}>
                        <MdClose />
                    </button>
                    {chatProps.modelType === "text" && (<Tooltip id="upload-tooltip" place="top-end" style={{ backgroundColor: "#CBD5E1", maxWidth: "400px" }} html={`
                        <div class="text-slate-900">
                            <h1 class="underline font-bold">Upload Function</h1>
                            <br/>
                            <p><span class="font-bold">Accepted File Formats:</span> pdf, docx, txt, csv, json<br/>(recommended less than 4 mb size)</p>
                            <br/>
                            <p><span class="font-bold">Accepted Image Formats:</span> jpeg/jpg, png, webp, gif<br/>(recommended resizing to less than 1568 pixels height and width and 4 mb file size)</p>
                        </div>
                    `} />)}
                    {chatProps.modelType === "image" && (<Tooltip id="upload-tooltip" place="top-end" style={{ backgroundColor: "#CBD5E1", maxWidth: "400px" }} html={`
                        <div class="text-slate-900">
                            <h1 class="underline font-bold">Upload Function</h1>
                            <br/>
                            <p><span class="font-bold">Note:</span> Resizing and padding of uploaded image may occur due to input requirements to the model.</p>
                            <br/>
                            <p><span class="font-bold">Accepted Image Formats:</span> jpeg/jpg, png, webp (will attempt to reformat other image types to png)</p>
                            <br/>
                            <p>(Recommended resizing upload to less than 1536 pixels height and width and 4 mb file size)</p>
                        </div>
                    `} />)}
                </div>
            </>)}


            <div className="flex items-stretch w-full join border rounded-t-2xl">
                <div className="p-4 border-r-[0.5px] border-gray-400">
                    <button
                        type="button"
                        onClick={chatProps.handleEnterDropdown}
                    >
                        <FaPlus />
                    </button>
                </div>

                <textarea className="input grow outline-none focus:outline-none rounded-t-3xl disabled:bg-white max-h-40 min-h-12 pt-2.5 resize-none"
                    value={chatProps.formValue}
                    ref={chatProps.inputRef}
                    disabled={chatProps.waiting}
                    onKeyDown={chatProps.handleKeyDown}
                    onChange={(e) => chatProps.setFormValue(e.target.value)}
                    placeholder="Ask me anything..." />
                <button className={`btn join-item bg-light-blue rounded-l-none rounded-tr-xl rounded-br-none h-full hover:bg-dark-blue ${chatProps.waiting ? "hidden" : ""}`}
                    type="submit"
                    disabled={(!chatProps.formValue && !chatProps.selectedFileInfo?.file_name) || chatProps.waiting}>
                    <img src={Airplane} alt="send" /></button>
                <button className={`btn join-item bg-light-blue rounded-l-none rounded-tr-xl rounded-br-none h-full hover:bg-dark-blue ${chatProps.waiting ? "" : "hidden"}`}
                    type="button"
                    onClick={chatProps.cancelResponse}>
                    <FaStop alt="Stop Generating" /></button>
            </div>
        </form>
    );
};

export default ChatFormDesktop;
