import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import ImageResponse from '../../Image';
import person from '../../../assets/avatar_human.png';
import bot from '../../../assets/avatar_bot.png';
import thumbsUp from '../../../assets/thumbs_up.png';
import thumbsDown from '../../../assets/thumbs_down.png';
import regenerate from '../../../assets/regenerate.png';
import copy from '../../../assets/copy.png';
import { FaRegBookmark } from "react-icons/fa";

const ChatMessageDesktop = (props) => {
  const { id, text, total_tokens, ai, error, feedback, sendFeedback, handleCopyToClipboard, handleEnterSavePrompt, textToCopyRef, modelType, onRegenerate } = props;

  return (
    <div
      key={id}
      className={`${ai ? 'assistant' : 'human'} ${ai && 'flex-row-reverse bg-light-white'} message mx-8`}>
      {error ? (
        <div className='message__wrapper bg-red-100 shadow-xl mt-2 p-8 rounded-lg rounded-tl-none ml-4 mr-40'>
          {text}
        </div>
      ) : modelType === 'image' && ai ? (
        <div className="message__wrapper bg-gray-100 shadow-xl mt-2 p-4 rounded-lg rounded-tl-none ml-4 mr-40">
          <ImageResponse raw={text} key={id} />
          <div className='copy w-full flex justify-end'>
            <button className={`btn btn-ghost btn-sm rounded-full mr-2 ${feedback === 1 ? 'bg-green-200' : ''}`} onClick={() => sendFeedback(id, feedback === 1 ? 0 : 1)}>
              <img src={thumbsUp} alt='thumbs up' />
            </button>
            <button className={`btn btn-ghost btn-sm rounded-full mr-2 ${feedback === -1 ? 'bg-red-200' : ''}`} onClick={() => sendFeedback(id, feedback === -1 ? 0 : -1)}>
              <img src={thumbsDown} alt='thumbs down' />
            </button>
            <button className='btn btn-ghost btn-sm rounded-full' onClick={onRegenerate}>
              <img src={regenerate} alt='regenerate' />
            </button>
          </div>
        </div>
      ) : (
        <div className={`message__wrapper bg-gray-100 shadow-xl mt-2 p-4 rounded-lg ${ai ? 'rounded-tl-none ml-4 mr-40' : 'rounded-tr-none mr-4 ml-40'}`} ref={textToCopyRef}>
          {props.message.turnType === "image" ? (
            <ImageResponse raw={text} key={id} />
          ) : (
            <ReactMarkdown
              className={`message__markdown prose text-left`}
              children={!ai && text.length > 1000 ? text.substring(0, 2000) + '... \n\n(plus ' + (text.length - 2000) + ' additional characters)' : text}
              remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
              rehypePlugins={[rehypeRaw, rehypeSanitize]}
              components={{
                code({ node, inline, className, children, ...props }) {
                  const match = /language-(\w+)/.exec(className || '')
                  return match ? (
                    <SyntaxHighlighter
                      children={String(children).replace(/\n$/, '')}
                      style={oneDark}
                      language={match[1]}
                      PreTag='div'
                      {...props}
                    />
                  ) : (
                    <code className={className} {...props}>
                      {children}
                    </code>
                  );
                },
                p({ className, children, ...props }) {
                  return <p className={`${className ? className : ""} whitespace-pre-wrap`}>{children}</p>;
                },
              }}
            />
          )}
          {ai && (
            <div className="text-left mt-6">
              <small>Token usage: {total_tokens?.toLocaleString()} / 200,000</small>
            </div>
          )}

          {ai && (
            <div className='copy w-full flex justify-end'>
              <button className={`btn btn-ghost btn-sm rounded-full mr-2 ${feedback === 1 ? 'bg-green-200' : ''}`} onClick={() => sendFeedback(id, feedback === 1 ? 0 : 1)}>
                <img src={thumbsUp} alt='thumbs up' />
              </button>
              <button className={`btn btn-ghost btn-sm rounded-full mr-2 ${feedback === -1 ? 'bg-red-200' : ''}`} onClick={() => sendFeedback(id, feedback === -1 ? 0 : -1)}>
                <img src={thumbsDown} alt='thumbs down' />
              </button>
              <button className='btn btn-ghost btn-sm rounded-full' onClick={handleCopyToClipboard}>
                <img src={copy} alt='copy' />
              </button>
              <button className='btn btn-ghost btn-sm rounded-full' onClick={onRegenerate}>
                <img src={regenerate} alt='regenerate' />
              </button>
              <button className='btn btn-ghost btn-sm rounded-full mr-2' onClick={handleEnterSavePrompt}>
                <FaRegBookmark />
              </button>
            </div>
          )}
          {!ai && (
            <div className='flex flex-row-reverse'>
              <button className='btn btn-ghost btn-sm rounded-full' onClick={handleEnterSavePrompt}>
                <FaRegBookmark />
              </button>
            </div>
          )}
        </div>
      )}

      <div className='message__pic'>
        {ai ? (
          <div className='avatar'>
            <div className='w-8 border rounded-full'>
              <img src={bot} alt='bot pic' />
            </div>
          </div>
        ) : (
          <div className='avatar'>
            <div className='w-8 border rounded-full'>
              <img src={person} alt='profile pic' />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatMessageDesktop;