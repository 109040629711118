import React, { useState } from 'react';
import skechai from '../../../assets/Dark_BG_SkechAI_logo_sm.png';
import { Menu, Settings } from 'react-ionicons';
import arrowDown from '../../../assets/dropdown_arrow_down.png';
import arrowUp from '../../../assets/dropdown_arrow_up.png';
import ModelTypeDropdown from './ModelTypeDropdown';

const HeaderMobile = ({ uiState, showSettings = true }) => {

    const { modelType, setSideBarOpen, onModelTypeChange, dropdownOpen, setDropdownOpen, isConfigOpen, setIsConfigOpen } = uiState;
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const handleModelTypeChangeAndClose = (newModelType) => {
        onModelTypeChange(newModelType);
        setDropdownOpen(false);
    };

    return (
        <div className='mobile__header flex flex-col items-center p-4 relative'>
            <div className="flex justify-between w-full items-center">
                <Menu onClick={() => setSideBarOpen(prev => !prev)} color={'#FFF'} height="40px" width="40px" />
                <div className='sidebar__app-logo-mobile flex flex-col items-center'>
                    <img src={skechai} className='skechai-logo' alt='SkechAI logo' />
                </div>
                
                {showSettings ? (
                <Settings 
                    onClick={() => setIsConfigOpen(!isConfigOpen)} 
                    color={'#FFF'} 
                    height="40px" 
                    width="40px" 
                />
                ) : (
                <div class = "w-10 h-10" />
                )}

            </div>

            {/* Dropdown button */}
            <div className="dropdown__container mt-4 relative">
                <button
                    onClick={toggleDropdown}
                    className="dropdown__button"
                >
                    {modelType === 'text' ? 'Text Generation' : 'Image Generation'}
                    <img
                        src={dropdownOpen ? arrowUp : arrowDown}
                        alt={dropdownOpen ? "Close dropdown" : "Open dropdown"}
                        className="ml-2 w-4 h-4"
                    />
                </button>

                {/* Dropdown content */}
                {dropdownOpen && (
                    <div className="model-dropdown">
                        <ModelTypeDropdown modelType={modelType} onModelTypeChange={handleModelTypeChangeAndClose} />
                    </div>
                )}
            </div>
        </div>
    );
}

export default HeaderMobile;