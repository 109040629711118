import use_case_1 from '../../assets/use_case_1.png';
import use_case_2 from '../../assets/use_case_2.png';
import use_case_3 from '../../assets/use_case_3.png';
import use_case_4 from '../../assets/use_case_4.png';

import RootCauseDiagnostics, { reportTypes as RootCauseDiagnosticsReportTypes } from './RootCauseDiagnostics';
import RootCauseReportSelection, { reportTypes as RootCauseReportTypes } from './RootCauseReportSelection';

const UseCases = [
  {
    modelType: 'text',
    title: 'Contract Summary',
    key: 'contract_summary_menu',
    environments: ['*'],
    description: `
    <h2 class="font-bold">Purpose</h2>
    Use SkechAI to instantly summarize the following contract types:
    <ul class="list-disc mx-6">
    <li>General Contract</li>
    <li>Endorsement Contract</li>
    <li>Licensing Agreement</li>
    </ul>
    <br/><br/>
    <h2 class="font-bold">Instructions</h2>
    <ol class="list-decimal mx-6">
    <li>Upload your contract using the paperclip icon.</li>
    <li>Follow the instructions on the prompt that appears once the document has uploaded.</li>
    <li>Once SkechAI provides you with an initial analysis, you can ask follow-up questions about the contract or the analysis provided by SkechAI.</li>
    </ol>
    <br/>
    <br/>
    We've set configuration parameters on the right-hand side for low creativity and high accuracy; however, you can adjust these parameters to get different results.
    `,
    imageName: use_case_1,
    template: `
You are to act as a legal analyst specifically for Skechers. Your task involves summarizing a contract provided to you. 

In order to do so you will need to ask me what type of document analysis to prepare. The available options are as follows. Please Show these options back to me when asking which one I'd like to use:

Analysis Types:
- General Contract
- Endorsement Contract
- Licensing Agreement

Upon receiving the specific type of contract, refer to the detailed instructions provided for that contract type to complete both a comprehensive summary and a narrative-style summary tailored for Skechers leadership.
Follow these steps for EACH document sent:
1. Confirm the analysis type to be performed by showing the user the list and asking them to choose.
2. Do NOT generate the analsysis until the user has explicitly confirmed the analysis type by choosing from the list provided.
3. Once the contract type is identified, proceed with the summary as per the instructions related to the chosen type.

Here are instructions for you to follow to prepare your summary for each report type:

### General Contract:

Follow these steps:

1. Read the contract below

2. Write a COMPLETE SUMMARY including the following points:
- Parties Involved
- Contract purpose (2-3 sentences)
- Payment Terms (including total fees)
- Skechers' obligations (if any present, otherwise omit) 
- Termination fees (if any present, otherwise omit) 
- Benefit to Skechers

3. Remember the summary. We'll refer to it as #SUMMARY

4. Use the information from #SUMMARY and format it into a narrative style paragraph form summary. Remember this narrative style paragraph form summary. We'll refer to it as #NARRATIVE.

5. Print out ONLY the #NARRATIVE and #SUMMARY for the user to review. Do not provide any additional lead-in or follow-up text in addition to the format requested. Do not explain the steps to take or instructions you will follow to generate the analysis.

### Endorsement Contract:

Follow these steps:

1. Read the contract below

2. Write a COMPLETE SUMMARY including the following points:
- Parties involved
- Product endorsed
- Length of the endorsement deal
- Overview of the compensation Skechers will provide, including the following if applicable: fees, stock grants, royalty payments, base royalty, product offered to endorsee
- Endorser obligations for the following is applicable: promotions, appearances, social media, production days
- Additional commitments from Skechers including the following in applicable: charitable contributions.
- If the contract contains a bonus
3. Remember the summary. We'll refer to it as #SUMMARY

4. Use the information from #SUMMARY and format it into a narrative style paragraph form summary. Remember this narrative style paragraph form summary. We'll refer to it as #NARRATIVE.

5. Print out ONLY the #NARRATIVE and #SUMMARY for the user to review. Do not provide any additional lead-in or follow-up text in addition to the format requested. Do not explain the steps to take or instructions you will follow to generate the analysis.

### Licensing Agreement:

Follow these steps:

1. Read the contract below

2. Write a COMPLETE SUMMARY including the following points:
- Parties involved
- Type of product
- Skechers trademark
- Territory
- Contract Terms
- Payment terms including the following if applicable: royalties, advance, minimums. Do not include the following: projected sales, selloff, dispute resolution.  3. Remember the summary. We'll refer to it as #SUMMARY

4. Use the information from #SUMMARY and format it into a narrative style paragraph form summary. Remember this narrative style paragraph form summary. We'll refer to it as #NARRATIVE.

5. Print out ONLY the #NARRATIVE and #SUMMARY for the user to review. Do not provide any additional lead-in or follow-up text in addition to the format requested. Do not explain the steps to take or instructions you will follow to generate the analysis.

### End of list

Here is the contract:
<text>
{{PROMPT}}
</text>
`,
    config: {
      temperature: 0.7,
      topP: 0.9,
      topK: 50,
      maxTokens: 4096,
      stopSequences: [],
    }
  },
  {
    modelType: 'text',
    key: 'root_cause_analysis',
    title: 'Root Cause Analysis',
    environments: ['*'],
    roles: ['root_cause_analysis'],
    reportParamsModal: RootCauseReportSelection,
    reportTypes: RootCauseReportTypes,
    description: `
    <h2 class="font-bold">Purpose</h2>
    Use Skechers to analyze customer support call transcripts for root causes and preventative actions.
    <br/><br/>
    <h2 class="font-bold">Instructions</h2>
    Initiate your root cause analysis by selecting your desired time period along with the specific type of analysis you want to undertake. Click "Go" to begin.
`,
    imageName: use_case_1,
    config: {
      temperature: 0.5,
      topP: 1,
      topK: 250,
      maxTokens: 2000,
      stopSequences: [],
    }
  },
  {
    modelType: 'text',
    key: 'root_cause_diagnostics',
    title: 'Root Cause Diagnostics',
    environments: ['*'],
    roles: ['KPMG Consultants'],
    reportParamsModal: RootCauseDiagnostics,
    reportTypes: RootCauseDiagnosticsReportTypes,
    description: `
    <h2 class="font-bold">Purpose</h2>
    Get detailed listing of call logs for the selected scope, including date/time, duration, sentiment, root causes and reasons, and ID.
    <br/><br/>
    <h2 class="font-bold">Instructions</h2>
    Select the date range for the call logs you want to analyze, then click "Go" to begin.
`,
    imageName: use_case_1,
    config: {
      temperature: 0.5,
      topP: 1,
      topK: 250,
      maxTokens: 2000,
      stopSequences: [],
    }
  },
  {
    modelType: 'text',
    key: 'product_description_generation',
    title: 'Product Description Generation',
    environments: ['*'],
    description: `
    <h2 class="font-bold">Purpose</h2>
    Use SkechAI to generate product descriptions for Skechers’ products. SkechAI will leverage the style and feature information provided and generate potential product descriptions that captivate your audience.
    <br/><br/>
    <h2 class="font-bold">Instructions</h2>
    <ul class="list-decimal mx-6">
    <li>Upload your product spreadsheet (.csv) using the paperclip icon or copy/paste the text into the chat and hit send. </li>
    <li>When prompted, tell SkechAI which product or products you want descriptions for, by style name. You can also just say "the first 10", for example, or "all of them".</li>
    <li>Once SkechAI provides you with an initial analysis, you can request edits to the product descriptions provided.</li>
    </ul>
    We've preset configuration parameters on the right-hand side for you to encourage a little creativity, but you can adjust these parameters to get different results.
    `,
    imageName: use_case_2,
    template: `

Save this information for our conversation: {{PROMPT}}
  
After reading and saving the above, prompt me for which products I want by saying something like “Got it. Just tell me which products you’re interested in and what product line they belong to and I”ll generate descriptions for them”. Then use the following instruction to generate descriptions as requested:
  
You are a marketing copywriter. Using the format below, create a description appealing to a broad set of buyers the requested product(s).
  
The general format is 2 sentences, totaling 55 words or less. Contents of each sentence are:
First sentence: a strong romance statement describing overall appeal of the shoe, followed by product line and full product name. Do not include features of the shoe in the first sentence. 
Seconds sentence: begin with a transition and then list out the features of the shoe in plain text with no added creativity. Do not add anything after listing the features. Only use features listed for this shoe.
  
It is very important to EXCLUDE these items from the feature list: heel height, Skechers® logo detail, information about electrical hazard safety, or the word “shoe”.
    `,
    config: {
      temperature: 0.7,
      topP: 0.9,
      topK: 50,
      maxTokens: 2000,
      stopSequences: [],
    }
  },
  {
    modelType: 'text',
    key: 'employee_survey_summarization',
    title: 'Survey Summarization',
    environments: ['*'],
    description: `
    <h2 class="font-bold">Purpose</h2>
    Use SkechAI to summarize employee surveys from retail stores. SkechAI will carefully analyze your survey and provide actionable insights on the following, if applicable:
    <ul class="list-disc mx-6">
    <li>Employee satisfaction insights</li>
    <li>Product insights</li>
    <li>Actions to be taken to improve employee satisfaction</li>
    <li>Actions to be taken to improve the product</li>
    </ul>
    <br/><br/>
    <h2 class="font-bold">Instructions</h2>
    <ul class="list-decimal mx-6">
    <li>Upload your survey results (PDF, Word, or CSV) using the paperclip icon or copy/paste the text into the chat and hit send.</li>
    <li>Once SkechAI provides you with an initial analysis, you can ask follow-up questions about the survey results or the analysis provided by SkechAI.</li>
    </ul>
    We've preset configuration parameters on the right-hand side for you; however,  you can adjust these parameters to get different results.
    `,
    imageName: use_case_4,
    template: 
   `You are an analyst at Skechers shoe company. You have been given the results of an survey related to Skechers.
    Read the survey data and write a narrative summary.
    Then based on the survey, draw actionable insights on
    1. Employee Satisfaction
    2. Product Insights
    3. Actions to be taken to improve Employee satisfaction
    4. Actions to be taken to improve the Product

    <text>
    {{PROMPT}}
    </text>
    `,
    config: {
      temperature: 0.7,
      topP: 0.9,
      topK: 50,
      maxTokens: 2000,
      stopSequences: [],
    }
  },
  {
    modelType: 'text',
    title: 'Investor Relations',
    key: 'investor_relations',
    environments: ['*'],
    description: `
    <h2 class="font-bold">Purpose</h2>
    Use SkechAI to instantly review public earnings documents from competitors or peers and extract the following information:
    <ul class="list-disc mx-6">
    <li>Company KPIs</li>
    <li>Segment KPIs</li>
    <li>Segment Summary</li>
    <li>Key Topics</li>
    <li>Frequent Topics</li>
    <li>Forward Guidance</li>
    <li>Transcript Analyst QA Summary</li>
    </ul>
    <br/><br/>
    <h2 class="font-bold">Instructions</h2>
    <ul class="list-decimal mx-6">
    <li>Upload a document; transcript, sell-side analyst report, or press release (in PDF or Word format) using the paperclip icon.</li>
    <li>Follow the instructions on the prompt that appears once the document has uploaded.</li>
    <li>Instruct the GenAI model to create one of the listed reports in a new prompt by typing in "Generate [insert report type here]" in the chat box, then hit enter/send button.</li>
    <li>Wait for GenAI model to produce a response.</li>
    <li>Some of the reports require you to copy/paste a list of items into the chat window as a 'Step 2' after asking to generate the report; make sure to paste a delineated (comma) or enumerated list of items/topics/segments.</li>
    </ul>
    We've preset configuration parameters on the right-hand side for you; however,  you can adjust these parameters to get different results.
    `,
    imageName: use_case_1,
    template: `
    You are an investment analyst who is reviewing various company earnings documents that I will provide to you. Your job is to prepare reports for me about the documents.
 
    In order to do so you will need to ask me what type of report to prepare. The available options are as follows. Please Show these options back to me when asking which one I'd like to use:
    
    - Company KPIs
    - Segment KPIs
    - Segment Summary
    - Key Topics
    - Frequent Topics
    - Forward Guidance
    - Transcript Analyst QA Summary

    Whenever you show the list to me, please format it as a bullet list.
    
    Here are instructions for you to follow to prepare your report for each report type:
    
    ### Company KPIs:

    Extract all financial KPIs reported in the text that match 'Quarterly' (current quarter) and 'Annual' (current fiscal year).  Also, provide the quote for 'Quarterly' and 'Annual' from the text where the KPI was found.
    
    Rules for output:
    
    1. Each value in a sentence should be extracted independently. If both revenue and revenue growth KPIs are in the same sentence, return both KPIs.
    
    2. Exclude forward guidance KPIs (expected/anticipated future financial performance).
    
    3. Exclude segment-specific KPIs.
    
    4. Ensure each revenue value scale is included as applicable, and always use the abbreviation; (M=millions, B=billions, etc.).
    
    5. Respond in table format with the following column names; “KPI Name”, "Quarterly Value", “Quarterly Quote”, "Annual Value", and “Annual Quote”.  If there is no value for a KPI at a given frequency, use "Not found" in the cell. 
    
    6. Do not provide any additional lead in or follow up text in addition to the formatted table requested.
    
    ### Segment KPIs
    
    First, you need to ask me for a list of segments that I'm interested in for this report. Call this list #KPI_SEGMENTS.
    
    Extract all financial KPIs reported in the text that match 'Quarterly' (current quarter) and 'Annual' (current fiscal year) for the following company segments:
    #KPI_SEGMENTS
    Also, provide the quote for 'Quarterly' and 'Annual' from the text where the KPI was found.
    
    Rules for output:
    
    1. Each value in a sentence should be extracted independently. If both revenue and revenue growth KPIs are in the same sentence, return both KPIs.
    
    2. Exclude forward guidance KPIs (expected/anticipated future financial performance).
    
    3. Include KPIs only for the company segments listed above.
    
    4. Ensure each revenue value scale (M, B, etc.) is included as applicable; Always use the abbreviation.
    
    5. Respond in table format with the following column names; “KPI Name”, "Quarterly Value", “Quarterly Quote”, "Annual Value", and “Annual Quote”.  If there is no value for a KPI at a given frequency, use "Not found" in the cell. 
    
    6. Do not provide any additional lead in or follow up text in addition to the format requested. 
    
    
    ### Segment Summary
    
    First, you need to ask me for a list of segments that I'm interested in for this report. Call this list #SUMMARY_SEGMENTS.
    
    Extract all text from the document relating to each of the segments in the following list; 
    #SUMMARY_SEGMENTS
    Rules for output:
    1. Respond in a table format with the following columns; "Segment Name", "Summary", and "Mention Count". Text under the “Summary” column should be in bullet point format.
    
    2. If no information is provided for a segment, say "No information provided". 
    
    3. Do not provide any additional lead in text, follow up text, or numeric bullets in addition to the format requested
    
    ### Key Topics
    
    First, start by asking for a list of topics that I'm interested in for this report. Call this list #KEY_TOPICS

    Extract all text from the document that relates to each of the topics in the following list. 
    #KEY_TOPICS

    Use the relative frequency of mention for each topic to score the Mention Count as 'High', 'Medium', 'Low', or 'None'.
    
    Rules for output:
    
    1. Respond in a table format with the following columns; "Topic Name", "Summary", and "Mention Count". Text under the “Summary” column should be in bullet point format.
    
    2. If no information is provided for a topic, return a mention count of 'None' and say "No information provided". 
    
    3. Do not provide any additional lead in text, follow up text, or numeric bullets in addition to the format requested.



    ### Frequent Topics

    Automatically identify frequently mentioned topics within the document into a list #FREQUENT_TOPICS.

    Extract all text from the document that relates to each of the topics in the following list. 
    #FREQUENT_TOPICS

    Use the relative frequency of mention for each topic to score the Mention Count as 'High', 'Medium', 'Low', or 'None'.
    
    Rules for output:
    
    1. Respond in a table format with the following columns; "Topic Name", "Summary", and "Mention Count". Text under the “Summary” column should be in bullet point format.
    
    2. If no information is provided for a topic, return a mention count of 'None' and say "No information provided". 
    
    3. Do not provide any additional lead in text, follow up text, or numeric bullets in addition to the format requested.
    
    
    ### Forward Guidance
    
    Extract all of the forward guidance provided in the text.
    
    Forward guidance is defined as: Company projections/expectations for revenue, earnings, capital spending, and other financial performance KPIs.
    
    Rules for output: 
    
    1. 'Frequency' should be either 'quarterly', 'year-to-date', or ‘fiscal year’.
    
    2. Ensure each revenue value scale is included as applicable, and always use the abbreviation; (M=millions, B=billions, etc.).
    
    3. Respond in table format with the following column names; “KPI Name”, “KPI Value”, “Frequency”, and “Quote from Document”.  The column “Frequency” should stipulate whether the KPI is a quarterly, year-to-date, or a fiscal year metric. 
    
    4. Do not provide any additional lead in or follow up text in addition to the format requested. 
    
    ### Transcript Analyst QA Summary
    
    First, identify every sell-side institution who asked a question during the earnings call in the transcript.
    
    Next, one by one, for each sell-side institution, generate a table using the following rules that includes each question they asked the question.
    1. Respond in table format with the following columns; "Question Topic", “Question Summary”, "Company Response Summary”, and “Direct Quote”.
    2. “Question Summary” and “Company Response Summary” should be in bullet point format.
    3. “Direct Quote” should be an exact quote from the text that supports the “Company Response Summary” bullets.
    4. Do not provide any additional lead in or follow up text in addition to the format requested.
    
    Here is the report:
    <text>
    {{PROMPT}}
    </text>
    
    After you prepare and show me your report, I may ask follow-up questions about the document, or I may ask for a new report on the same document, or I may want to send you a new document to analyze. 
    
    `,
    config: {
      temperature: 0.7,
      topP: 0.9,
      topK: 50,
      maxTokens: 4096,
      stopSequences: [],
    }
  },

  {
    modelType: 'text',
    key: 'RAG_Kendra',
    title: 'RAG Test, Kendra, call transcript analysis',
    environments: ['none'],
    // terraform kendra index
    rag: {
      "method": "kendra",
      "identifier": "2966e512-ebe9-4f6b-8920-b020e26a3322",
      "template": ""
    },
    description: `
    Test against the Kendra index with a call transcript analysis.
`,
    imageName: use_case_1,
    template: ``,
    config: {
      temperature: 0.5,
      topP: 1,
      topK: 250,
      maxTokens: 2000,
      stopSequences: [],
    }
  },
  {
    modelType: 'text',
    key: 'RAG_kb_1',
    title: 'RAG Test, Bedrock Knowledge Base, call transcript analysis',
    environments: ['none'],
    // bedrock knowledge base
    rag: {
      "method": "kb",
      "identifier": "FUGHZPW8XR",
      "template": ""
    },
    description: `
    Test against the Bedrock Knowledge Base with call transcripts
`,
    imageName: use_case_1,
    template: ``,
    config: {
      temperature: 0.5,
      topP: 1,
      topK: 250,
      maxTokens: 2000,
      stopSequences: [],
    }
  },
  {
    modelType: 'text',
    key: 'RAG_KB_Security_Architecture',
    title: 'Security Architecture Knowledge Base',
    environments: ['dev'],
    // bedrock knowledge base
    rag: {
      "method": "kb",
      "identifier": "ZOIESTZLH3",
      "template": ""
    },
    description: `
    Ask security architecture related questions with knowledge supplied from Skechers documentation.
`,
    imageName: use_case_1,
    template: ``,
    config: {
      temperature: 0.5,
      topP: 1,
      topK: 250,
      maxTokens: 2000,
      stopSequences: [],
    }
  },

//   {
//     modelType: 'text',
//     key: 'call_log_queries',
//     title: 'Test prepared & ad-hoc MongoDB queries',
//     environments: ['dev'],
//     reportParamsModal: RootCauseReportSelection,
//     description: `
//     Test using pre-set queries against call log data, or ad-hoc.
//     <br/><br/>
//     For an ad-hoc query, start your prompt with "query: "
// `,
//     imageName: use_case_1,
//     template: ``,
//     config: {
//       temperature: 0.5,
//       topP: 1,
//       topK: 250,
//       maxTokens: 2000,
//       stopSequences: [],
//     }
//   },

  {
    modelType: 'image',
    title: 'Idea Generation',
    key: 'idea_generation',
    description: 'Generate images to spark creativity.',
    imageName: use_case_3,
    template: "",
  },


  // Travel documents usecase
  {
    modelType: 'text',
    key: 'travel_queries',
    title: 'Travel Documents',
    environments: ['dev', 'prod'],
    rag: {
      dev: {
        "method": "kb",
        "identifier": "4Y8YSS0WND",
        "template": ""
      },
      prod: {
        "method": "kb",
        "identifier": "AHLOLWY2PZ",
        "template": ""
      }
    },
    description: `
    Use SkechAI to get relevant information about:
    <ul class="list-disc mx-6">
    <li>Concur Expense Guides</li>
    <li>Concur Expense New User Interface Training</li>
    <li>Duty of Care, International SOS</li>
    <li>Employee Travel Offers</li>
    <li>Global Travel and Meeting Policy</li>
    <li>Passports & Visas</li>
    <li>Skecher Corporate Credit Card</li>
    <li>Travel and Meeting Forms</li>
    <li>Travel Insurance Information</li>
    <li>Travel Resources and News</li>
    <li>Travel Vendors</li>
    <li>TripSource</li>
    <li>Virtual Meeting Resources</li>
`,
    imageName: use_case_1,
    template: ``,
    config: {
      temperature: 0.4,
      topP: 0.75,
      topK: 40,
      maxTokens: 2000,
      stopSequences: [],
    }
  },

  // Employee Handbook Usecase
  {
    modelType: 'text',
    key: 'employee_handbook_queries',
    title: 'Employee Handbook US Corporate',
    environments: ['dev', 'prod'],
    rag: {
      dev: {
        "method": "kb",
        "identifier": "92M6KM1CYA",
        "template": ""
      },
      prod: {
        "method": "kb",
        "identifier": "YO0GNLOMJV",
        "template": ""
      }
    },
    description: `
    <h2 class="font-bold">Purpose</h2>
    Use SkechAI to retrieve important policies and guidelines outlined in the Skechers Employee Handbook.
    <br/><br/>
    <h2 class="font-bold">What's Covered</h2>
    You can ask SkechAI to provide information on the following topics:
    <ul class="list-disc mx-6">
    <li>Equal Employment Opportunity (EEO) and Anti-Discrimination Policies</li>
    <li>Harassment and Retaliation Prevention</li>
    <li>Reasonable Accommodations (Disability, Pregnancy, Religious)</li>
    <li>Work Schedules and Core Business Hours</li>
    <li>Overtime and Pay Policies for Non-Exempt Employees</li>
    <li>Leave of Absence and FMLA Policies</li>
    <li>Vacation, Sick Leave, and Personal Days</li>
    <li>Employee Conduct Rules and Disciplinary Procedures</li>
    <li>Employee Benefits, including Group Health Insurance and 401(k) Plans</li>
    <li>Safety and Workplace Security</li>
    <li>Reporting Procedures for Workplace Concerns</li>
    </ul>
    `,
    imageName: use_case_1,
    template: ``,
    config: {
      temperature: 0.4,
      topP: 0.75,
      topK: 40,
      maxTokens: 2000,
      stopSequences: [],
    }
  },
 
];

export default UseCases;